<script>
'use strict'
export default {
  data () {
    return {
      expressionERP: {
        regex: /^[0-9A-Z_-]{1,100}$/i
      },
      expression: {
        regex: /^[0-9A-Z, _-]*$/i
      },
      loggedUser: {},
      actions: [],
      getRulesCompany: []
    }
  },

  methods: {

  
    validaRut(rutCompleto) {
		if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
			return false;
		var tmp 	= rutCompleto.split('-');
		var digv	= tmp[1]; 
		var rut 	= tmp[0];
		if ( digv == 'K' ) digv = 'k' ;
		return (this.dv(rut) == digv );
	},
	dv(T){
		var M=0,S=1;
		for(;T;T=Math.floor(T/10))
			S=(S+T%10*(9-M++%6))%11;
		return S?S-1:'k';
	},

    isNumber (evt) {
      evt = evt || window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },

    isZero (evt, value) {
      console.log(value)
      if (value === '0') {
        console.log('NO HACE NADA')
        evt.preventDefault()
        return 1
      } else {
        return true
      }
    },
 
  }
}
</script>
