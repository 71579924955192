<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-toolbar color="primary">
            <v-row align="center">
              <v-col class="grow">
                <span style="color: white"> Actualizar Alumno</span>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-form class="mx-4 my-4"  v-model="valid">
            <v-row class="mb-3">
              <v-col cols="12" md="4">
                <v-text-field prepend-icon="mdi-card-account-details" :counter="11" v-model="rut" :rules="rutRules"
                  placeholder="12345678-9" label="RUT *" type="text"></v-text-field></v-col>
          
              <v-col cols="12" md="4">
                <v-text-field
                  prepend-icon="mdi-account"
                  v-model="name"
                  :rules="nameRules"
                  label="Nombre *"
                  type="text"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  prepend-icon="mdi-alpha-p-box"
                  v-model="last_name"
                  :rules="last_name_Rules"
                  label="Apellido Paterno *"
                  type="text"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  prepend-icon="mdi-alpha-m-box"
                  v-model="mother_last_name"
                  :rules="mother_last_name_Rules"
                  label="Apellido Materno *"
                  type="text"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  prepend-icon="mdi-gender-male-female"
                  v-model="sex"
                  
                  label="Género"
                  type="text"
                ></v-text-field
              ></v-col>

              <v-col cols="12" md="4">
                <template>
                  <v-container>
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="mt-0 pt-0"
                          v-model="computedDateFormatted"
                          label="Fecha de Nacimiento *"
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        locale="es-cl" 
                        no-title
                        :max="maxDate"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-container>
                </template>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  auto-select-first
                  chips
                  clearable
                  deletable-chips
                  dense
                  v-model="holder_proxy"
                  :items="guardians"
                  item-text="name"
                  item-value="id"
                  prepend-icon="mdi mdi-account-star"
                  label="Apoderado Titular"
                  @change="relationHp()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  prepend-icon="mdi mdi-account-child"
                  v-model="relation_hp"
                  label="Relación con el apoderado titular"
                  type="text"
                  :disabled="!enable_hp"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  auto-select-first
                  chips
                  clearable
                  deletable-chips
                  dense
                  v-model="secondary_proxy"
                  :items="guardians"
                  item-text="name"
                  item-value="id"
                  prepend-icon="mdi mdi-account-badge"
                  label="Apoderado Secundario"
                  @change="relationSp()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  prepend-icon="mdi mdi-account-child"
                  v-model="relation_sp"
                  label="Relación con el apoderado secundario"
                  type="text"
                  :disabled="!enable_sp"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  prepend-icon="mdi-cellphone"
                  v-model="mobile"
                  :rules="mobileRules"
                  v-mask="'+56#########'"               
                  label="Teléfono"
                  type="text"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="region"
                  no-data-text="No hay datos disponibles"
                
                  :items="regions"
                  item-text="name"
                  item-value="id"
                  prepend-icon="mdi-map"                
                  label="Región"
                  @change="getCommunes()"
                  persistent-hint
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="commune"
                  no-data-text="No hay datos disponibles"
                 
                  :items="communes"
                  item-text="name"
                  item-value="id"
                  prepend-icon="mdi-account-details"                
                  label="Comuna"
                  persistent-hint
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  v-model="address"
                  :rules="addressRules"
                  label="Dirección"
                  type="text"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  prepend-icon="mdi-email-fast"
                  v-model="email"
                  :rules="emailRules"
                  label="Correo Electrónico"
                  type="text"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="grade"
                  no-data-text="No hay datos disponibles"                  
                  :items="grades"
                  item-text="name"
                  item-value="id"
                  prepend-icon="mdi-account-details"
                
                  label="Curso"
                  persistent-hint
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="8">
                <v-text-field
                  prepend-icon="mdi-eye"
                  v-model="observations"
                  label="Observaciones"
                  type="text"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-divider class="mb-3"></v-divider>
          <v-row>
            <v-col cols="12" md="9"></v-col>
            <v-col cols="12" md="3">
              <v-btn to="/student" text> Cancelar </v-btn>
              <v-btn color="primary" :disabled="!valid" @click="updateStudent()">
                Aceptar
              </v-btn></v-col
            >
          </v-row>
          </v-form>
        
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import UtilitiesMixin from '@/mixins/UtilitiesMixin.vue'

export default {
  mixins: [SnotifyMixin, UtilitiesMixin],
  VueSnotify,
  data() {
    return {
    
    date: new Date().getFullYear() - 6 + '-12-31',    
      maxDate: new Date().getFullYear() - 6 + '-12-31',
    dateFormatted: this.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    e1: 1,
    activePicker: null,
    menu: false,
    valid: true,
    valid2: true,
    valid3: true,
    data: {},
    menu2: false,

    //Datos del Estudiante
    rut: "",
    name: "",
    last_name: "",
    mother_last_name: "",
    birth_date: null,
    sex: "",
    email: "",
    mobile: "",
    grade: "",
    age: "",
    region: "",
    commune: "",
    observations: "",
    address: "",

    //Datos del Apoderado Titular
    holder_proxy: "",
    holder_proxies: [],
    enable_hp: false,
    relation_hp: "",

    //Datos del Apoderado Secundario
    secondary_proxy: "",
    secondary_proxies: [],
    enable_sp: false,
    relation_sp: "",

    regions: [],
    communes: [],
    grades: [],
    guardians: [],

    //Reglas del Estudiante
    rutRules: [
        (v) => !!v || "El RUT es requerido",
        (v) => (v && v.length <= 11) || "El RUT debe tener menos de 12 caracteres",
        (v) => this.validaRut(v) || 'El RUT no es válido'],

      nameRules: [
        (v) => !!v || "El Nombre es requerido",
        (v) =>
          (v && v.length <= 40) ||
          "El Nombre debe tener menos de 41 caracteres",      
         (v) => /^[a-zA-ZáÁéÉíÍóÓúÚñÑ\s']+$/.test(v) || "El Nombre no es válido",],

      last_name_Rules: [
        (v) => !!v || "El Apellido Paterno es requerido",
        (v) =>
          (v && v.length <= 30) ||
          "El Apellido Paterno debe tener menos de 31 caracteres",
          (v) => /^[a-zA-ZáÁéÉíÍóÓúÚñÑ\s']+$/.test(v) || "El Apellido Paterno no es válido",
      ],

      mother_last_name_Rules: [
        (v) => !!v || "El Apellido Materno es requerido",
        (v) =>
          (v && v.length <= 30) ||
          "El Apellido Materno debe tener menos de 31 caracteres",
          (v) => /^[a-zA-ZáÁéÉíÍóÓúÚñÑ\s']+$/.test(v) || "El Apellido Materno no es válido",
      ],

    birth_date_Rules: [(v) => !!v || "La Fecha de Nacimiento es requerida"],

   
    sexRules: [(v) => !!v || "El Género es requerido"],

    gradeRules: [(v) => !!v || "El Curso es requerido"],

    ageRules: [(v) => !!v || "La Edad es requerida"],
    mobileRules: [
      (v) => /^\+56(9)([1-9])(\d{7})$/.test(v) || "El Teléfono no es válido",      
    ],

    addressRules: [
   
      (v) =>
        (v.length <= 200) ||
        "La Dirección debe tener menos de 201 caracteres",
    ],

    emailRules: [
      (v) => !!v || "El Correo Electrónico es requerido",
      (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico no es válido",
    ],
  }},

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
      console.log(val);
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },

  mounted() {
    console.log("entra al mounted");
    this.getRegions();
    //this.getCommunes();
    this.getGrades();
    this.getGuardians();
    this.chargeStudent();
  },

  methods: {
    getGuardians() {
      axios
        .get("/guardian-index")
        .then((response) => {
          this.returns = response.data;
          console.log("imprime apoderados");
          console.log(response.data);
          this.returns.forEach((element) => {
            this.guardians.push({
              name:
                "RUT: " +
                element.rut +
                " Nombre: " +
                element.name +
                " " +
                element.last_name +
                " " +
                element.mother_last_name,
              id: element.id,
            });
          });
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los apoderados"
          );
        });
    },

    relationHp() {
      if (this.holder_proxy != null && this.holder_proxy != "") {
        this.enable_hp = true;
      } else {
        this.enable_hp = false;
        this.relation_hp = "";
      }
      if (
        this.holder_proxy == this.secondary_proxy &&
        this.secondary_proxy != ""
      ) {
        this.holder_proxy = "";
      }
    },
    relationSp() {
      if (this.secondary_proxy != null && this.secondary_proxy != "") {
        this.enable_sp = true;
      } else {
        this.enable_sp = false;
        this.relation_sp = "";
      }
      console.log("entra a hacer comparacion apoderado primario");
      console.log("valor holder_proxy " + this.holder_proxy);

      console.log("entra a hacer comparacion apoderado secundario");
      console.log("valor secondary_proxy " + this.secondary_proxy);
      if (
        this.secondary_proxy == this.holder_proxy &&
        this.holder_proxy != ""
      ) {
        console.log("entra a la ocmparacion");
        this.secondary_proxy = -1;
      }
    },
 
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    save(date) {
      this.$refs.menu.save(date);
    },

    getRegions() {
      axios
        .get("/region-index")
        .then((response) => {
          this.regions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las regiones"
          );
        });
    },

    
    getCommunes() {
      console.log("Region----------------------->");
      console.log(this.region);
      this.data.region_id = this.region;

      axios
        .post("/commune-show-by-region", this.data)
        .then((response) => {
          this.communes = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },
    getCommunesS() {
      this.data.region_id = this.as_region;
      axios
        .post("/commune-show-by-region", this.data)
        .then((response) => {
          this.communes = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },
    getCommunesP() {
      this.data.region_id = this.ap_region;

      axios
        .post("/commune-show-by-region", this.data)
        .then((response) => {
          this.communes = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },
    getGrades() {
      axios
        .get("/grade-index")
        .then((response) => {
          this.grades = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los cursos"
          );
        });
    },
    chargeStudent() {
      console.log("entra a cargar estudiante");
      let request = {
        id: this.$route.params.id,
      };
      axios
        .post("/student-show", request)
        .then((response) => {
          console.log("imprime response");
          console.log(response.data);
          this.rut = response.data.student.rut;
          this.name = response.data.student.name;
          this.last_name = response.data.student.last_name;
          this.mother_last_name = response.data.student.mother_last_name;
          this.date = response.data.student.birth_date;
          this.sex = response.data.student.sex;
          this.email = response.data.student.email;
          this.mobile = response.data.student.mobile;
          this.grade = parseInt(response.data.student.grade_id);
          this.age = response.data.student.age;
          this.region = parseInt(response.data.student.region_id);
          this.commune = parseInt(response.data.student.commune_id);
          this.observations = response.data.student.observations;
          this.address = response.data.student.address;

          this.getCommunes();
          //Datos apoderado primario
          this.results = response.data.student.guardians;
          this.results.forEach((element) => {
            if (element.pivot.is_main_guardian == 1) {
              this.holder_proxy = parseInt(element.pivot.guardian_id);
              this.enable_hp = true;
              this.relation_hp = element.pivot.relationship;
            } else if (element.pivot.is_main_guardian == 0) {
              this.secondary_proxy = parseInt(element.pivot.guardian_id);
              this.enable_sp = true;
              this.relation_sp = element.pivot.relationship;
            }
          });
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "warning",
            "Advertencia",
            "Alumnos con Datos incompletos"
          );
        });
    },
    updateStudent() {
     // this.$refs.form.validate();
      if (this.valid) {
        console.log("entra a actualizar");
        this.data.id = this.$route.params.id;
        this.data.rut = this.rut;
        this.data.name = this.name;
        this.data.last_name = this.last_name;
        this.data.mother_last_name = this.mother_last_name;
        this.data.birth_date = this.date;
        this.data.sex = this.sex;
        this.data.email = this.email;
        this.data.mobile = this.mobile;
        this.data.grade = this.grade;
        this.data.age = this.age;
        this.data.region = this.region;
        this.data.commune = this.commune;
        this.data.observations = this.observations;
        this.data.address = this.address;
        this.data.holder_proxy = this.holder_proxy;
        this.data.secondary_proxy = this.secondary_proxy;
        this.data.relation_hp = this.relation_hp;
        this.data.relation_sp = this.relation_sp;
        axios
          .post("/student-update", this.data)
          .then(() => {
            this.displayNotification(
              "success",
              "Éxito",
              "Alumno actualizado correctamente"
            );
            setTimeout(() => this.$router.push({ name: "student" }), 3000);
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification(
              "error",
              "Error",
              "Ha ocurrido un error al actualizar el Alumno"
            );
          });
      }
    },
  },
};
</script>
