<template>
  <div>
    <vue-snotify></vue-snotify>
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4 ">

          <v-toolbar id="bar-login" dark color="primary">
            Control de Acceso
            <v-spacer></v-spacer>

            <v-menu offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-btn outlined class="white--text ma-5" v-bind="attrs" v-on="on">
                  <v-icon>mdi-door-open</v-icon><span class="ml-1">{{ store.name }}</span>
                </v-btn>
                
                
              </template>

              <v-list>
                <v-list-item v-for="item in items" :key="item" link @click="updateDoor(item)">
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
           
          </v-toolbar>

          <v-row class="mt-2">
           
           

            <v-col cols="10" md="10">
              <v-text-field class="ml-12" prepend-icon="mdi-qrcode-scan" v-model="scanned_code"
                label="Escanear Credencial" type="password" :ref="scannerCredential"
                @keyup.enter="scannerCode()"></v-text-field>
            </v-col>

            <v-col cols="2" md="2">
              <v-btn color="primary" @click="scannerCode">
                <v-icon>mdi-check-circle</v-icon><span>Aceptar</span>
              </v-btn>
            </v-col>
          </v-row>
         
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="show">
      <v-col cols="12" md="12">
        <v-row class="mt-2">
          <v-col cols="12" md="12">
            <v-card :color="color" dark>
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>

                  <v-card-title>
                    <h2>{{ user_name }}</h2>

                  </v-card-title>

                  <v-card-subtitle>
                    <h2>{{ user_type }}</h2>
                    <h2 class="mt-3">{{ user_access }}</h2>
                    <h3>RUT:{{ user_rut }}</h3>
                    <h3>Teléfono: {{ mobile }}</h3>
                    <h3>Email: {{ email }}</h3>
                    <h3>Fecha y Hora: {{ time }}</h3>
                  </v-card-subtitle>

                </div>

                <v-avatar class="ma-3" size="200" tile>
                  <v-img :src="getImagePath()"></v-img>
                </v-avatar>
              </div>
            </v-card>


            <v-alert class="mt-4" v-if="showMotive" v-model="alert" :color="color" border="left" elevation="2"
              colored-border icon="mdi-lock">


              <v-row align="center">
                <v-col class="grow">
                  <strong>{{ motive }}</strong>
                </v-col>

              </v-row>
            </v-alert>

            <v-alert class="mt-4" v-if="showAlert" v-model="alert" :color="color" border="left" elevation="2"
              colored-border icon="mdi-timer">


              <v-row align="center">
                <v-col class="grow">
                  <strong>[{{ type }}] </strong> - {{ message }} {{ ingreso_tardio }}
                </v-col>
                <v-col class="shrink">
                  <v-btn>
                    <v-icon left>
                      mdi-printer
                    </v-icon>
                    Imprimir Ticket
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>

            <v-alert class="mt-4" v-if="showAlert && ingreso_tardio >= 5" v-model="alert" :color="color" border="left"
              elevation="2" colored-border icon="mdi-email">
              <v-row align="center">
                <v-col class="grow">
                  <strong>[ENVIO DE NOTIFICACION] </strong> - Se ha enviado una citación al apoderado
                </v-col>

              </v-row>
            </v-alert>



          </v-col>


        </v-row>

      </v-col>
    </v-row>
  </div>
</template>
<script>

import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import { useDoorStore } from '../../store/index'
import { useInstitutionStore } from '../../store/institution'
import axios from "axios";

const { ConectorPluginV3 } = require("../../plugins/conector")

export default {
  mixins: [SnotifyMixin],
  VueSnotify,
  setup() {
    const store = useDoorStore()
    const storeInstitution = useInstitutionStore()
    return { store, storeInstitution }
  },
  data() {
    return {

     
      id_action: 0,
     
      late_arrivals: 0,
      scannerCredential: "",
      motive: "",
      ingreso_tardio: 0,
      drawer: true,
      user_type: "",
      email: "",
      mobile: "",
      scanned_code: "",
      user_name: "",
      user_group: "",
      user_access: "",
      user_rut: "",
      user_token: "",
      message: "",
      type: "",
      show: false,
      color: "red",
      showAlert: false,
      showMotive: false,
      date: '',
      grades: [],
      dateScanner: '',
      time: '',
      currentDate: new Date(),
      access: "",
      door: { id: 0, name: "Acceso" },
      items: [],
      flagPrint: false,
    }
  },

  mounted: function () {
    this.$refs[this.scannerCredential].focus()
    this.date = this.printDate();
    this.getGrades();
    this.getAccess();
    setInterval(() => {
      this.currentDate = new Date();
    }, 1000);

  },
  methods: {

    updateDoor(door) {
      console.log(door)
      this.store.setDoor(door.id, door.name)
      this.$refs[this.scannerCredential].focus()
    },
    getAccess() {
      axios
        .get("/entrance-index")
        .then((response) => {
          console.log(response.data)
          this.items = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los accesos"
          );
        });
    },
/*
    getImagePath() {

      let url = axios.defaults.baseURL.split("api/")[0] + `rut/${this.user_rut}.jpg`
      return url
    },*/

    getImagePath() {
  let timestamp = new Date().getTime(); // Genera un valor único basado en el tiempo
  let url = axios.defaults.baseURL.split("api/")[0] + `rut/${this.user_rut}.jpg?nocache=${timestamp}`;
  return url;
},

    getGradeById(grade_id) {
  let grade = this.grades.find((c) => c.id == grade_id);
  // Comprueba si grade existe antes de intentar acceder a su propiedad 'name'
  if (grade) {
    return grade.name;
  } else {
    // Devuelve un valor predeterminado si no se encuentra grade
    return "No disponible"; // Puedes cambiar esto por null o '' según necesites
  }
},

    printDate: function () {
      return new Date().toLocaleDateString();
    },
    printTime: function () {
      return new Date().toLocaleTimeString();
    },
    printYear: function () {
      return new Date().getFullYear();
    },
    printTimestamp: function () {
      return Date.now();
    },
    printFullDate: function () {
      return new Date();
    },

   // async imprimir(nameInstitution, nameStudent, gradeStudent, dateScanner) {
   
    async imprimir() {
      /*
      conector.fontsize("2")
      conector.textaling("center")
      conector.text(nameInstitution)
      conector.fontsize("1")
      conector.text("Ticket de atraso N° " + this.late_arrivals)
      conector.feed("1")
      conector.textaling("left")
      conector.text("Nombre: " + nameStudent)
     // conector.text("R.U.T: " + this.user_rut)
      conector.text("Curso: " + gradeStudent)
     
      //  conector.feed("2")
      conector.textaling("left")
      conector.text("Fecha: " + dateScanner)
      conector.fontsize("3")
      conector.feed("1")
      conector.textaling("center")
      //conector.barcode_128()                       
      conector.qr(dateScanner)
      conector.fontsize("1")
      conector.text("Luupa v1.0")
      conector.feed("5")
      conector.cut("0")

      const resp = await conector.imprimir(nombreImpresora, api_key);
      if (resp === true) {
        console.log("Problema al imprimir: " + resp)
      } else {
        console.log("Problema al imprimir: " + resp)

      }*/
    },
    getGrades() {
      axios
        .get("/grade-index")
        .then((response) => {
          this.grades = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },

    scanner() {
      axios
        .post("/store-scanner")
        .then(() => {
          // this.functions = response.data; 
         
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las funciones"
          );
        });
    },


    scannerCode() {
      if (this.store.id == 0) {
        this.scanned_code = "";
        this.displayNotification(
          "error",
          "Error",
          "Debe configurar el Acceso"
        )

      }   
        else {
          let request = {
            token: this.scanned_code,
            id_entrance: this.store.id,
            id_action: this.id_action
          };
          this.show = false


          this.flagPrint = false,
            axios
              .post("/store-scanner", request)
              .then((response) => {

                console.log("Acceso")
                console.log(response.data.access)

                if (response.data.access === "authorized") {
                  this.color = response.data.color
                  this.user_name = response.data.person.name + " " + response.data.person.last_name + " " + response.data.person.mother_last_name
                  this.user_rut = response.data.person.rut
                  this.mobile = response.data.person.mobile
                  this.email = response.data.person.email
                  this.user_type = response.data.user_type
                  this.time = response.data.dateScanner
                  this.user_access = "Acceso Autorizado"
                  this.show = true
                  this.scanned_code = ""
                  this.$refs[this.scannerCredential].focus()
                }
                else
                  if (response.data.access === "no_authorized") {
                    this.color = response.data.color
                    this.user_name = response.data.person.name + " " + response.data.person.last_name + " " + response.data.person.mother_last_name
                    this.user_rut = response.data.person.rut
                    this.mobile = response.data.person.mobile
                    this.email = response.data.person.email
                    this.user_type = response.data.user_type
                    this.time = response.data.dateScanner
                    this.user_access = "Acceso Denegado"
                    this.showMotive = true
                    this.motive = response.data.motive
                    this.show = true
                    this.scanned_code = ""
                    this.$refs[this.scannerCredential].focus()      

                  }

                  else if (response.data.access === "authorized_late") {


                    console.log("entra a ingreso tardío")
                    console.log(response.data.person)
                    this.color = "orange"
                    this.user_name = response.data.person.name + " " + response.data.person.last_name + " " + response.data.person.mother_last_name
                    this.user_rut = response.data.person.rut
                    this.mobile = response.data.person.mobile
                    this.email = response.data.person.email
                    this.user_type = response.data.user_type
                    this.user_group = this.getGradeById(response.data.person.grade_id)
                    this.late_arrivals = response.data.person.late_arrivals
                    this.user_access = "Acceso Retrasado"
                    this.show = true
                    this.scanned_code = ""
                    this.flagPrint = true,
                      this.dateScanner = response.data.dateScanner
                    this.time = response.data.dateScanner
                    this.$refs[this.scannerCredential].focus()
                  }

                  else if (response.data.access === "authorized_early") {
                    this.color = "orange"
                    this.user_name = response.data.person.name + " " + response.data.person.last_name + " " + response.data.person.mother_last_name
                    this.user_rut = response.data.person.rut
                    this.mobile = response.data.person.mobile
                    this.email = response.data.person.email
                    this.user_type = response.data.user_type
                    this.user_group = this.getGradeById(response.data.person.grade_id)
                    this.late_arrivals = response.data.person.late_arrivals
                    this.user_access = "Retiro Anticipado"
                    this.show = true

                    this.scanned_code = ""
                    this.flagPrint = false,
                      this.dateScanner = response.data.dateScanner
                    this.time = response.data.dateScanner
                    this.$refs[this.scannerCredential].focus()
                  }


                  else {
                    this.color = "red"
                    this.user_name = response.data.person.name + " " + response.data.person.last_name + " " + response.data.person.mother_last_name
                    this.user_rut = response.data.person.rut
                    this.mobile = response.data.person.mobile
                    this.email = response.data.person.email
                    this.user_type = response.data.user_type
                    this.user_access = "Acceso Denegado"
                    this.show = true
                    this.scanned_code = ""
                    this.$refs[this.scannerCredential].focus()
                  }

              })
              .catch((err) => {
                this.scanned_code = ""
                this.$refs[this.scannerCredential].focus()
                console.log(err, "error");
                this.displayNotification("error", "Error", "Credencial no válida");
              })
              .finally(() => {
                if (this.flagPrint) {
                //this.imprimir(this.storeInstitution.getInstitution.name, this.user_name, this.user_group, this.dateScanner)
                let nombreImpresora = "luupa-printer";
     // let api_key = "7d86f73d-b6f1-4a96-a465-7a837bfce724";
   

const licencia = "YzYyZTM2NTNfXzIwMjQtMDktMjVfXzIwMjQtMTAtMjUjIyNYdmREQ0FUVUx2MFBndzFiVlBjSGhsZDhPZldXM0V4L3hhZENWMko2alpFU1RBMVN4SEtNejA1ZENObkNpRDJEQ3dSd25Dekx5R21oOVNsU2VzYUlDREpIUVltc2swSnZDSHFzRnNxVmt0RXo3OXAyOHZmcWFtOTBRY1pNRnlPVlhXSzhnUzFRdXdCSkdXU0FXcHNJN3JFL3pqdmdDeThnakNlNlFoQ21ndEw5UmJUYXJvM3JMUW5NdEhGa2JwQy8xK3pKMDFXVk5IQWVNSkdSVkVKUHF0dW8vOEptOE5vMlluOXQyV25FbCs0M2tSdjJBK2xURVZkakFHQWdVeGw0WFdzaXFKelQ0b3BQckRsNkFWeXRkWnFsZjVOV2NGcjgycXY0eThETnhsNCtVN0doamJMT3QwSXllMHMvWm90OU1qVkhHMGNxSkdxMkcxS2xQWlpYdjVQVzRxaVRMczlwT0JFdWdNeGZlZUV6TzZzbmg3YzE2WVlSeWNCbkwxbEtMSC9BMG1QWDdGbzlBb1hJTm0vUG5ReXRlRGlWc1hMZThNd1FzSE13aVdaU1AxMDE3QWMraEN6NU9BYklsRHV0TEFpV1Bqcms3SWNuWnB5d20zTDhSbnE1VGgyeVpkS3MwOHBIOVp4dUllVFNYL25LditFRXdwM3o1VXpUM3pzQlZGSzQwN05jWlhVOEcwekx2N1hOTmcrMlUwd0txMXJUN0VvYjgyVVRPcEdRUHZsWG4vQlA4NjVGVzRNTkcxek1VSU1zeEc0cFVaNHVKQ1ZZckYwYmRSU2RsbnpOZU5vZDYvdXpHcHRjQ000bjRGd2d4dldPcElNNEFmUlBZYjlXMnN5V1FOVkxFQmdwQ0RyYzJQUUlVdThmVXFCc2hCVzZPNnN2ZTM4NVNwMD0="; // Aquí la licencia en caso de que cuentes con ella
const conector = new ConectorPluginV3(null, licencia);

conector
    .Iniciar().
    Corte(1)
    .EstablecerAlineacion(ConectorPluginV3.ALINEACION_CENTRO)
   // .DescargarImagenDeInternetEImprimir("https://apiluupa.klint.cl/image/logo.png", 0, 216)
   .EscribirTexto("----------------------------------------\n")
    .EscribirTexto("Ticket de Retraso : #" + this.late_arrivals + "\n")  
    .EscribirTexto("\n")
    .EscribirTexto(this.user_name + "\n")
    .EscribirTexto(this.user_group+ "\n")
    .EscribirTexto("\n")
    .EscribirTexto( (new Intl.DateTimeFormat("es-MX", { 
  year: 'numeric', 
  month: 'long', 
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
}).format(new Date()))+ "\n")
    .Feed(1)
    .EscribirTexto("----------------------------------------\n")   
    .EscribirTexto("Luupa" +"\n")  
    .Corte(1)
    .Pulso(48, 60, 120);

    conector.imprimirEn(nombreImpresora)
    .then(respuesta => {
        if (respuesta === true) {
            console.log("Impreso correctamente");
        } else {
            console.log("Error: " + respuesta);
        }
    });
    
                
                console.log("imprimiendo");
                }

              });


        }
    },
  },
};
</script>