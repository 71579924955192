<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-toolbar color="primary">
            <v-row align="center">
              <v-col class="grow">
                <span style="color: white">Agregar funcionario
 </span>
              </v-col>
              <v-btn :href="link" target="_blank" color="mr-2">
                <v-icon left> mdi-microsoft-excel </v-icon>
              Archivo Base de Funcionarios
              </v-btn>
              
            </v-row>
          </v-toolbar>
          <v-progress-linear v-if="visible"
            color="primary"
            indeterminate
            rounded
            height="5"          
          ></v-progress-linear>
          <v-card-text>
            <template>
              <v-file-input
                v-validate="'required'"
                ref="fileInput"
                v-model="file"
                name="file"
                accept=".xls, .xlsx"
                label="Seleccione el archivo a importar"
                @change="handleFileUpload"
              ></v-file-input>
              <v-divider class="my-3"></v-divider>
              <v-row>
                <v-col cols="12" md="9"></v-col>
                <v-col cols="12" md="3">
                  <v-btn to="/official" text> Cancelar </v-btn>
                  <v-btn color="primary" :disabled="!valid" @click="uploadFile">
                    Aceptar
                  </v-btn></v-col
                >
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
      link:"",
      visible:false,
      drawer: true,
      dialog: false,
      search: "",
      cont: 0,
      file: "",
      fileNames: [],
      valid: false,
      headers: [
        { text: "No", value: "id" },
        { text: "Rol", value: "name" },
        { text: "Acciones", value: "actions" },
      ],
      roles: [],
      officials: [],
    };
  },


  mounted() {
    this.action = "Eliminar";   
    this.link=axios.defaults.baseURL.split("api/")[0]+"excel/Archivo%20base%20Funcionarios.xlsx";
    
  },

  methods: {
    handleFileUpload() {
      console.log("entra a subir archivo");
      this.valid = true;


    },
    uploadFile() {
      this.visible=true
      let flag= true
      const formData = new FormData();
      formData.append("file", this.file);
      this.valid = false;
      axios
        .post("/official-upload", formData)
        .then((response) => {
          console.log(response.data);
        
        })
        .catch(() => {
          this.displayNotification("error", "Error", "Error al cargar archivo, hay problemas en el llenado de los datos");
          this.visible=false
          flag=false
        })
        .finally(() => {  
          
          if (flag) {
            this.displayNotification(
            "success",
            "Exito",
            "Se ha cargado el archivo correctamente"
          );
          }
          
          setTimeout(() => this.$router.push({ name: "official" }), 3000);
          this.visible=false
        });
    },
  },
};
</script>
