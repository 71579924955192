<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-form pt-4 v-model="valid">
            <v-toolbar color="primary">
              <v-row align="center">
                <v-col class="grow">
                  <span style="color: white"> Asignar Rol a Funcionario</span>
                </v-col>
              </v-row>
            </v-toolbar>
            <v-row class="my-4">
              <v-col cols="2"></v-col>

              <v-col cols="6" md="6">
                <v-text-field
                  v-model="rut_user"
                  :loading="loading"
                  density="compact"                  
                  label="Ingrese el RUT del funcionario (ej.12345678-0)"
                  hide-details
                  @click:append-inner="onClick"
                ></v-text-field>
              </v-col>
              <v-col cols="1" md="1" class="mt-3">
                <v-btn color="primary" @click="onClick()"> Buscar </v-btn>
              </v-col>
              <v-col cols="4"></v-col>
            </v-row>
            <v-row v-if="visible">
              <v-col cols="2"></v-col>
              <v-col cols="7" class="">
                <v-card outlined>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        Datos del Funcionario
                      </div>
                      <v-list-item-title class="mb-1">
                        <div class="mb-1">
                          <strong>Nombre:</strong>
                          <spam class="pl-2">{{ namePerson }}</spam>
                        </div>
                        <div class="mb-1">
                          <strong>Correo:</strong>
                          <spam class="pl-2">{{ email }}</spam>
                        </div>
                        <div class="mb-1">
                          <strong>Telefono:</strong>
                          <spam class="pl-2">{{ phone }}</spam>
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="3"></v-col>
              <v-col cols="2"></v-col>
              <v-col cols="7">
                <v-combobox
                  v-model="selectedRol"
                  label="Seleccionar Rol"
                  :items="roles"
                  item-text="name"
                  item-value="name"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="9"></v-col>
              <v-col cols="12" md="3">
                <v-btn to="/role" text> Cancelar </v-btn>
                <v-btn
                  color="primary"
                  :disabled="!valid"
                  @click="addRolToUser()"
                >
                  Aceptar
                </v-btn></v-col
              >
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
      namePerson: "",
      visible: false,
      selectedRol: "",
      name: "",
      firstName: "",
      id_user: "",
      rut_user: "",
      email: "",
      phone: "",
      loaded: false,
      loading: false,
      data: {},
      valid: true,
      roles: [],
      selected: "",
      permissions: [],
      permissions_selected: [],
      firstNameRules: [
        (value) => {
          if (value?.length > 3) return true;

          return "First name must be at least 3 characters.";
        },
      ],
      nameRules: [
        (v) => !!v || "El nombre del Rol es requerido",
        (v) =>
          (v && v.length <= 19) ||
          "El nombre del Rol debe tener menos de 20 caracteres",
      ],
    };
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    onClick() {
      //TODO
            
      this.data.rut = this.rut_user;
      axios
        .post("/official-by-rut", this.data)
        .then((response) => {
          console.log("imprime datos de usuario");

          if (typeof response.data.official !== "undefined" && response.data.official !== "") {
            this.visible = true;
            this.namePerson =
              response.data.official.name +
              " " +
              response.data.official.last_name +
              " " +
              response.data.official.mother_last_name;
            this.email = response.data.official.email;
            this.phone = response.data.official.mobile;
            this.id_user = response.data.user.id;
            this.selectedRol = response.data.rol_name;
          } else {
            this.visible = false;
            this.displayNotification(
              "warning",
              "Información",
              "No se encuentra el funcionario"
            );
          }
        })
        .catch((err) => {
          console.log(err, "error");
          this.visible = false;
          this.displayNotification("error", "Error", "Error en búsqueda");
        });
    },
    getPermissionsBydIdRol() {
      this.data.id = this.selected;
      this.permissions_selected = [];
      axios
        .post("/permissions-by-rol", this.data)
        .then((response) => {
          console.log("imprime permisios del rol");
          response.data.forEach((element) =>
            this.permissions_selected.push(element.name)
          );
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener permisos del rol seleccionado"
          );
        });
    },
    addRolToUser() {
      this.data.id = this.id_user;
      this.data.role = this.selectedRol.name;
      console.log("id de rol " + this.selected);
      (this.valid = false),
        axios
          .post("/add-rol-to-user", this.data)
          .then(() => {
            this.displayNotification(
              "success",
              "Éxito",
              "Permisos asociados satisfactoriamente"
            );
            setTimeout(() => this.$router.push({ name: "role" }), 3000);
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification(
              "error",
              "Error",
              "Error al agregar el rol al usuario "
            );
          });
    },
    getRoles() {
      axios
        .get("/role-index")
        .then((response) => {
          console.log(response.data);
          this.roles = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los Permisos"
          );
        });
    },
  },
};
</script>
