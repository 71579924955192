<template>
  <div >
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4 ">

          <v-toolbar color="primary">
            <v-row align="center">
              <v-col class="grow ">

                <span style="color: white;"> Institución</span>
              </v-col>

              <v-btn :disabled="invalid" to="/create-institution" color=" mr-2">
                <v-icon left>
                  mdi-account-multiple-plus
                </v-icon>
                Registrar institución
              </v-btn>

            </v-row>
          </v-toolbar>

          <v-card-text>
            <v-data-table :headers="headers"
          :items="institutions"
          :search="search"
          :loading="!allDataLoaded"
              loading-text="Cargando... Por favor espere"
          no-data-text="No hay datos disponibles"
          no-results-text="No hay datos disponibles"
          :footer-props="{
            itemsPerPageText: 'Filas por página',
            itemsPerPageAllText: 'todos',
          }"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          show-expand>

              <template v-slot:[`item.region_name`]="{ item }">
                <span>{{ getRegionById(item.region_id) }}</span>
              </template>

              <template v-slot:[`item.commune_name`]="{ item }">
                <span>{{ getCommuneById(item.commune_id) }}</span>
              </template>
              <template v-slot:[`item.end_date`]="{ item }">
                <span>{{ formatDate(item.end_date) }}</span>
              </template>

              <template v-slot:[`item.start_date`]="{ item }">
                <span>{{ formatDate(item.start_date) }}</span>
             </template>

              <template v-slot:[`item.whatsapp_notification`]="{ item }">
                <span>
                  <v-chip v-if="item.whatsapp_notification == 1" class="ma-2" color="teal" text-color="white">
                    <v-avatar left>
                      <v-icon>mdi-checkbox-marked-circle</v-icon>
                    </v-avatar>
                    Activadas
                  </v-chip>

                  <v-chip v-else class="ma-2">
                    <v-avatar left>
                      <v-icon>mdi-close-box</v-icon>
                    </v-avatar>
                    Desactivadas
                  </v-chip>
                </span>
              </template>

              <template v-slot:[`item.email_notification`]="{ item }">
                <span>
                  <v-chip v-if="item.email_notification == 1" class="ma-2" color="teal" text-color="white">
                    <v-avatar left>
                      <v-icon>mdi-checkbox-marked-circle</v-icon>
                    </v-avatar>
                    Activadas
                  </v-chip>

                  <v-chip v-else class="ma-2">
                    <v-avatar left>
                      <v-icon>mdi-close-box</v-icon>
                    </v-avatar>
                    Desactivadas
                  </v-chip>
                </span>
              </template>

              <template v-slot:expanded-item="{ item }">
            <td :colspan="3" class="pt-4 pb-4">
             Contacto: <br>
             <strong>Representante: {{ item.manager_name}}</strong
              ><br />
              <strong>Correo: {{ item.email}}</strong
              ><br />
            
         
            </td>
            <td :colspan="3" class="pt-4 pb-4">
             Dirección: <br>
             <strong>Ubicación: {{ item.direction}}</strong
              ><br />
            
            
         
            </td>
           
          </template>


              <template v-slot:[`item.actions`]="{ item }">

                <v-tooltip top>
                  <template v-slot:[`activator`]="{ on, attrs }">
                    <v-btn icon :to="'/update-institution/' + item.id">
                      <v-icon v-bind="attrs" color="primary" v-on="on">
                        mdi-pencil-box-multiple
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>Modificar</span>
                </v-tooltip>

                <v-dialog transition="dialog-bottom-transition" max-width="600">
           
                  <template v-slot:[`activator`]="{ on, attrs }">
                    
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon v-bind="attrs" color="red darken-2" v-on="on">
                        mdi-delete
                      </v-icon>
                    </v-btn>
             
                  </template>
             
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                      <v-card-text>
                        <div class="text-h7 pa-6">
                          ¿Estás seguro que deseas eliminar la institución?
                        </div>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                        <v-btn class="primary" text @click="
                          dialog.value = false;
                        deleteItem(item);
                                                ">Aceptar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>

               
              </template>


            </v-data-table>

          </v-card-text>

        </v-card>
      </v-col>


    </v-row>

  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
      expanded: [],
    singleExpand: false,
      drawer: true,
      dialog: false,
      search: "",
      cont: 0,
      invalid: true,
      allDataLoaded: false,

      headers: [
        { text: "RUT", value: "rut" },
        { text: "Nombre", value: "name" },
        //{ text: "Dirección", value: "direction" },
       // { text: "Correo", value: "email" },
        { text: "Región", value: "region_name" },
        { text: "Comuna", value: "commune_name" },
       // { text: "Gerente", value: "manager_name" },
        { text: "Fecha inicial", value: "start_date" },
        { text: "Fecha final", value: "end_date" },
        { text: "Notificación Whatsapp", value: "whatsapp_notification" },
        { text: "Notificación Correo", value: "email_notification" },
        { text: "Acciones", value: "actions" },
      ],

      institutions: [],
      functions: [],
      regions: [],
      communes: [],
    }
  },

   mounted() {
    this.action = "Eliminar"
    
     this.getRegions()
     this.getCommunes()
     this.getInstitutions()
   



  },

  methods: {

    formatDate(date) {
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    getRegionById(region_id) {

      console.log("Entra a buscar la region")
      console.log(this.regions)
      let region = this.regions.find((r) => r.id == region_id);
      console.log(region)
      return region.name;
    },

    getCommuneById(commune_id) {
      let commune = this.communes.find((c) => c.id == commune_id);
      return commune.name;
    },

    getFunctionById(function_official_id) {
      let function_official = this.functions.find((f) => f.id == function_official_id);
      return function_official.name;
    },


    getFunctions() {
      axios
        .get("/functionOfficial-index")
        .then((response) => {
          this.functions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las funciones"
          );
        });
    },

     getRegions() {
      axios
        .get("/region-index")
        .then((response) => {
          console.log("Carga Regiones");
          this.regions = response.data;
          console.log(this.regions);
        
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las regiones"
          );
        });
    },

     getCommunes() {
      axios
        .get("/commune-index")
        .then((response) => {
          console.log("Carga Comuna");
          this.communes = response.data;
          console.log( this.communes);
        
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },

    deleteItem(item) {

      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/institution-destroy", request)
        .then(() => {
          this.getInstitutions();

          this.displayNotification("success", "Éxito", "Institución Eliminada");

        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar la Institución"
          );
        });
    },
     getInstitutions() {
      axios
        .get("/institution-index")
        .then((response) => {
         
          console.log("Carga Institucion");
          this.institutions = response.data;
            console.log( this.institutions);
          this.invalid = this.institutions.length == 0 ? false: true;

          console.log("All Data");
          this.allDataLoaded=true;
          console.log(this.allDataLoaded);

        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al cargar datos de la institución"
          );
        });
    },
  },
};
</script>