<template>
  <div>
      <v-row>
          <vue-snotify></vue-snotify>
          <v-col cols="12" md="12">
              <v-card class="mt-2 mb-4 ">
                  <v-form pt-4 v-model="valid">
                      <v-toolbar color="primary">
                          <v-row align="center">
                              <v-col class="grow ">
                                  <span style="color: white;"> Actualizar Configuración de Evento</span>
                              </v-col>
                          </v-row>
                      </v-toolbar>


                      <v-row class="mt-4 ml-2 mr-2">
                          <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-swap-horizontal" v-model="name"
                                  :rules="nameRules" label="Nombre *" type="text"></v-text-field></v-col>                             

                          <v-col cols="12" md="4">
                              <v-text-field v-model="color_start" readonly hide-details
                                  label="Color de Evento de Entrada *" text>
                                  <template v-slot:append>
                                      <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16"
                                          :close-on-content-click="false">
                                          <template v-slot:activator="{ on }">
                                              <div :style="swatchStyle" v-on="on" />
                                          </template>
                                          <v-card>
                                              <v-card-text class="pa-0">
                                                  <v-color-picker hide-canvas hide-inputs hide-mode-switch hide-sliders
                                                      mode="hexa" show-swatches swatches-max-height="200"
                                                      v-model="color_start" flat />
                                              </v-card-text>
                                          </v-card>
                                      </v-menu>
                                  </template>
                              </v-text-field> <br></v-col>


                          <v-col cols="12" md="4">
                              <v-text-field v-model="color_end" readonly hide-details
                                  label="Color de Evento de Salida *" text>
                                  <template v-slot:append>
                                      <v-menu v-model="menu2" top nudge-bottom="105" nudge-left="16"
                                          :close-on-content-click="false">
                                          <template v-slot:activator="{ on }">
                                              <div :style="swatchStyle2" v-on="on" />
                                          </template>
                                          <v-card>
                                              <v-card-text class="pa-0">
                                                  <v-color-picker dot-size="25" hide-canvas hide-inputs hide-mode-switch
                                                      hide-sliders mode="hexa" show-swatches swatches-max-height="200"
                                                      v-model="color_end" flat />
                                              </v-card-text>
                                          </v-card>
                                      </v-menu>
                                  </template>
                              </v-text-field> <br></v-col>

                              
                              <v-col cols="12" md="4">
                          <v-switch v-model="institution_access" label="Entrada/Salida de la Institución"></v-switch>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-select
    v-model="selectedAccess"
    :items="access"
    item-text="name"
    item-value="id"
    label="Selecciona una opción"
    return-object
  ></v-select>
</v-col>        
                           
                      </v-row>

            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="9"></v-col>
              <v-col cols="12" md="3">
                <v-btn to="/event" text>
                  Cancelar
                </v-btn>

                <v-btn color="primary" :disabled="!valid" @click="update()">
                  Modificar
                </v-btn></v-col>
            </v-row>
          </v-form>
        </v-card>

      </v-col>
    </v-row>

  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
        return {
          selectedAccess:0,
            name: "",
            // Color inicial
            data: {},
            institution_access: false,
            valid: true,
            color_start: '',
            color_end: '',
            access:[],
            
            menu: false,
            menu2: false,

            nameRules: [
                v => !!v || 'El Nombre  es requerido',
                v => (v && v.length <= 19) || 'El Nombre debe tener menos de 20 caracteres',
            ],

        }
    },

    
  computed: {
        swatchStyle() {
            const { color_start, menu } = this
            return {
                backgroundColor: color_start,
                cursor: 'pointer',
                height: '25px',
                width: '50px',
                borderRadius: menu ? '50%' : '4px',
                transition: 'border-radius 200ms ease-in-out'
            }
        },

        swatchStyle2() {
            const { color_end, menu2 } = this
            return {
                backgroundColor: color_end,
                cursor: 'pointer',
                height: '25px',
                width: '50px',
                borderRadius: menu2 ? '50%' : '4px',
                transition: 'border-radius 200ms ease-in-out'
            }
        }
    },


  mounted() {
   this.getAccess()
    this.chargeData();
    
  },

  methods: {

    getAccess()
        {
            axios
        .get("/entrance-index")
        .then((response) => {  
          console.log(response.data)
          this.access = response.data;  
          console.log("------------------accesos-------------------------")
            console.log(this.access)       
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los accesos"
          );
        });
        },

    chargeData() {
      console.log("cargando datos");
      let request = {
        id: this.$route.params.id,
      };
      axios
        .post("/event-configuration-show", request)
        .then((response) => {
          console.log(response.data);
          this.name = response.data.name;
          this.color_start = response.data.color_start;
          this.color_end = response.data.color_end;
          this.institution_access = response.data.institution_access;
          this.selectedAccess= parseInt(response.data.id_entrance)
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al cargar la configuración");
        });
    },

    update() {
      console.log("cargando datos");
      this.data.name = this.name;
      this.data.id = this.$route.params.id
      this.data.color_start =  this.color_start;
      this.data.color_end = this.color_end;
      this.data.institution_access =  this.institution_access; 
      this.data.entrance=this.selectedAccess.id;

      this.valid = false,
        axios
          .post("/event-configuration-update", this.data)
          .then(() => {
            this.displayNotification(
              "success",
              "Éxito",
              "Configuración modificado"
            );
            setTimeout(() => this.$router.push({ name: "event" }), 3000);
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification(
              "error",
              "Error",
              "Error al modificar la Configuración"
            );
          });
    },



  },
};
</script>