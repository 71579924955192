<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-form pt-4 v-model="valid">
            <v-toolbar color="primary">
              <v-row align="center">
                <v-col class="grow">
                  <span style="color: white"> Registrar Funcionario</span>
                </v-col>
              </v-row>
            </v-toolbar>

            <v-card-text>
              <v-row class="mt-4 ml-4 mr-4">
                <v-col cols="12" md="4">
                  <v-text-field prepend-icon="mdi-card-account-details" :counter="11" v-model="rut" :rules="rutRules" 
                    placeholder="12345678-9" label="RUT *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4">
                  <v-text-field prepend-icon="mdi-account" v-model="name" :rules="nameRules" label="Nombre *"
                    type="text"></v-text-field></v-col>
                <v-col cols="12" md="4">
                  <v-text-field prepend-icon="mdi-alpha-p-box" v-model="last_name" :rules="last_name_Rules"
                    label="Apellido Paterno *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4">
                  <v-text-field prepend-icon="mdi-alpha-m-box" v-model="mother_last_name" :rules="mother_last_name_Rules"
                    label="Apellido Materno *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4">
                  <v-text-field prepend-icon="mdi-gender-male-female" v-model="sex" label="Género "
                    type="text"></v-text-field></v-col>
                <v-col cols="12" md="4">
                  <template>
                    <v-container>
                      <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                        max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field class="mt-0 pt-0" v-model="computedDateFormatted" label="Fecha de Nacimiento"
                            persistent-hint prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker locale="es-cl" v-model="birth_date" :max="maxDate" no-title
                          @input="menu2 = false"></v-date-picker>
                      </v-menu>
                    </v-container>
                  </template>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field prepend-icon="mdi-cellphone" v-model="mobile" :rules="mobileRules" v-mask="'+56#########'"
                    label="Teléfono *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4">
                  <v-select v-model="region" no-data-text="No hay datos disponibles" :rules="regionRules" :items="regions"
                    item-text="name" item-value="id" prepend-icon="mdi-map"  label="Región *"
                    @change="getCommunes()" persistent-hint>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select v-model="commune" no-data-text="No hay datos disponibles" :rules="communeRules"
                    :items="communes" item-text="name" item-value="id" prepend-icon="mdi-account-details"
                    label="Comuna *" persistent-hint>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field prepend-icon="mdi-map-marker" v-model="address" :rules="addressRules" label="Dirección *"
                    type="text"></v-text-field></v-col>
                <v-col cols="12" md="4">
                  <v-text-field prepend-icon="mdi-email-fast" v-model="email" :rules="emailRules"
                    label="Correo Electrónico *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4">
                  <v-text-field prepend-icon="mdi-calendar-account-outline
" v-model="contractHours" v-mask="'####'" label="Cantidad de Horas de Contrato" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4">
                  <v-select v-model="id_functionOfficial" no-data-text="No hay datos disponibles"
                    :rules="functionOfficialRules" :items="functions" item-text="name" item-value="id"
                    prepend-icon="mdi-account-details"  label="Función *" persistent-hint>
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="9"></v-col>
              <v-col cols="12" md="3">
                <v-btn to="/official" class="mr-2" text> Cancelar </v-btn>

                <v-btn color="primary" :disabled="!valid" @click="create()">
                  Aceptar
                </v-btn></v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import UtilitiesMixin from '@/mixins/UtilitiesMixin.vue'

export default {
  mixins: [SnotifyMixin, UtilitiesMixin],
  VueSnotify,
  data() {
    return {
      birth_date: new Date().getFullYear() - 16 + '-12-31',
      maxDate: new Date().getFullYear() - 16 + '-12-31',
      data: {},
      valid: true,
      menu2: false,

      //Datos del Funcionario
      numberMask: /^[0-9]*$/,
      rut: "",
      name: "",
      last_name: "",
      mother_last_name: "",

      sex: "",
      email: "",
      mobile: "",
      contractHours: 0,
      region: "",
      commune: "",
      functionOfficial: "",
      id_functionOfficial: 0,
      address: "",
      officials: [],
      functions: [],
      regions: [],
      communes: [],

      functionOfficialRules: [(v) => !!v || "La Función es requerida"],
   /*   rutRules: [
        (v) => !!v || "El RUT es requerido",
        (v) => (v && v.length <= 11) || "El RUT debe tener menos de 12 caracteres",
        (v) => this.validaRut(v) || 'El RUT no es válido'],
*/
      nameRules: [
        (v) => !!v || "El Nombre es requerido",
        (v) =>
          (v && v.length <= 40) ||
          "El Nombre debe tener menos de 41 caracteres",
        (v) => /^[a-zA-ZáÁéÉíÍóÓúÚñÑ\s']+$/.test(v) || "El Nombre no es válido",],

      last_name_Rules: [
        (v) => !!v || "El Apellido Paterno es requerido",
        (v) =>
          (v && v.length <= 30) ||
          "El Apellido Paterno debe tener menos de 31 caracteres",
        (v) => /^[a-zA-ZáÁéÉíÍóÓúÚñÑ\s']+$/.test(v) || "El Apellido Paterno no es válido",
      ],

      mother_last_name_Rules: [
        (v) => !!v || "El Apellido Materno es requerido",
        (v) =>
          (v && v.length <= 30) ||
          "El Apellido Materno debe tener menos de 31 caracteres",
        (v) => /^[a-zA-ZáÁéÉíÍóÓúÚñÑ\s']+$/.test(v) || "El Apellido Materno no es válido",
      ],

      regionRules: [(v) => !!v || "La Región es requerida"],
      communeRules: [(v) => !!v || "La Comuna es requerida"],

      mobileRules: [(v) => !!v || "El Teléfono es requerido",
      (v) => /^\+56(9)([1-9])(\d{7})$/.test(v) || "El Teléfono no es válido",      
    ],

      addressRules: [
        (v) => !!v || "La Dirección es requerida",
        (v) =>
          (v && v.length <= 200) ||
          "La Dirección debe tener menos de 201 caracteres",
      ],

      emailRules: [
        (v) => !!v || "El Correo Electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico no es válido",
      ],
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },

    date() {
      this.dateFormatted = this.formatDate(this.birth_date);
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.birth_date);
    },

    
    rutRules() {
      return [
        v => !!v || "El RUT es requerido",
        v => (v && v.length <= 11) || "El RUT debe tener menos de 12 caracteres",
        v => this.validaRut(v) || 'El RUT no es válido',
        v => !this.officials.some(official => official.rut === v) || 'Existe un Funcionario registrado con este RUT'
      ];
    },    
  },
  mounted() {
    this.action = "Eliminar";
    this.getFunctions();
    this.getRegions();
    this.getCommunes();
    this.getOfficials()
  },

  methods: {

    




 getOfficials() {
      axios
        .get("/official-index")
        .then((response) => {
          console.log("Funcionarios");
          console.log(response.data);
          this.officials = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los alumnos"
          );
        }).finally(() => {

        });
    },

    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    save(date) {
      this.$refs.menu.save(date);
    },
    getFunctions() {
      axios
        .get("/functionOfficial-index")
        .then((response) => {
          this.functions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las funciones"
          );
        });
    },

    getRegions() {
      axios
        .get("/region-index")
        .then((response) => {
          this.regions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las regiones"
          );
        });
    },

    getCommunes() {
      this.data.region_id = this.region;

      axios
        .post("/commune-show-by-region", this.data)
        .then((response) => {
          this.communes = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },

    create() {
      this.data.name = this.name;
     // this.valid = false,
      this.data.rut = this.rut;
      this.data.name = this.name;
      this.data.last_name = this.last_name;
      this.data.mother_last_name = this.mother_last_name;
      this.data.numbers_hours_contract = this.contractHours;
      this.data.email = this.email;
      this.data.mobile = this.mobile;
      this.data.direction = this.address;
      this.data.region_id = this.region;
      this.data.commune_id = this.commune;
      this.data.function_official_id = this.id_functionOfficial;
      this.data.birth_date = this.birth_date;
      this.data.sex = this.sex;

      axios
        .post("/official-store", this.data)
        .then(() => {
      
        
            this.displayNotification(
            "success",
            "Éxito",
            "Funcionario registrado"
          );
        
         
          setTimeout(() => this.$router.push({ name: "official" }), 3000);
        })
        .catch((err) => {
          console.log("Error");
          console.log(err);
          if (err.response.status == 409) {
            this.displayNotification(
              "error",
              "Error",
              err.response.data
            );
          }
          else if(err.response.data.errorInfo[1]==1062)
          {
            this.displayNotification(
              "error",
              "Error",
              "Ya existe un funcionario con este correo electrónico"
            );
          }
          else{
            this.displayNotification(
            "error",
            "Error",
            "Error al crear el funcionario"
          );
          }
          
        
        });
    },
  },
};
</script>
