<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4 ">

          <v-toolbar color="primary">
            <v-row align="center">
              <v-col class="grow ">

                <span style="color: white;"> Listado de Visitas</span>
              </v-col>

              <v-btn to="/create-visit" color=" mr-2">
                <v-icon left>
                  mdi-account-multiple-plus
                </v-icon>
                Registrar Visita
              </v-btn>

            </v-row>
          </v-toolbar>
          <v-row>
            <v-col cols="6"></v-col>
            <v-col cols="5" class="ml-10">
              <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
            </v-col>
          </v-row>
          <v-card-text>
            <v-data-table :headers="headers" :items="visits" :search="search"            
:loading="loading"
              loading-text="Cargando... Por favor espere"
            :footer-props="{
  itemsPerPageText: 'Filas por página',
  itemsPerPageAllText: 'todos',
}" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">

             

              <template v-slot:[`item.actions`]="{ item }">

                <v-tooltip top>
                  <template v-slot:[`activator`]="{ on, attrs }">
                    <v-btn icon :to="'/update-visit/' + item.id">
                      <v-icon v-bind="attrs" color="primary" v-on="on">
                        mdi-pencil-box-multiple
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>Editar</span>
                </v-tooltip>

                <v-dialog transition="dialog-bottom-transition" max-width="600">
                  <template v-slot:[`activator`]="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon v-bind="attrs" color="red darken-2" v-on="on">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                      <v-card-text>
                        <div class="text-h7 pa-6">
                          ¿Estás seguro que deseas eliminar la visita?
                        </div>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                        <v-btn class="primary" text @click="
  dialog.value = false;
deleteItem(item);
                      ">Aceptar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
loading:true,
      drawer: true,
      dialog: false,
      search: "",
      cont: 0,
      headers: [      
        { text: "RUT", value: "rut" },
        { text: "Nombre", value: "name" },
        { text: "Apellido Paterno", value: "last_name" },
        { text: "Apellido Materno", value: "mother_last_name" },
        { text: "Correo", value: "email" },
        { text: "Teléfono", value: "mobile" },
        { text: "Razón de visita", value: "reason_visit" },        
        { text: "Acciones", value: "actions" },
      ],

      visits: [],
     
    }
  },

  computed: {
    filteredItems() {
      return this.visit.filter(item => {
        return Object.values(item).some(value => {
          return this.removeAccents(value.toString().toLowerCase()).includes(this.removeAccents(this.searchTerm.toLowerCase()));
        });
      });
    }
  },

  mounted() {
    this.action = "Eliminar" 
    this.getVisits()
  },
  methods: {   

    removeAccents(str) {
      const accents = [
        /[\300-\306]/g, /[\340-\346]/g, // A, a
        /[\310-\313]/g, /[\350-\353]/g, // E, e
        /[\314-\317]/g, /[\354-\357]/g, // I, i
        /[\322-\330]/g, /[\362-\370]/g, // O, o
        /[\331-\334]/g, /[\371-\374]/g, // U, u
        /[\321]/g, /[\361]/g, // N, n
        /[\307]/g, /[\347]/g // C, c
      ];
      const nonAccents = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];
      for (let i = 0; i < accents.length; i++) {
        str = str.replace(accents[i], nonAccents[i]);
      }
      return str;
    },

    deleteItem(item) {      
      let request = {
        id: item.id,
      };
      axios
        .post("/visit-destroy", request)
        .then(() => {         
          this.displayNotification("success", "Éxito", "Visita Eliminada");
          this.getVisits()
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar la Visita"
          );
        });
    },
    getVisits() {
      axios
        .get("/visit-index")
        .then((response) => {      
          this.visits = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las visitas"
          );
        }).finally(()=>
        {
         
          this.loading=false
        });
        
      
    },


  },
};
</script>