<template>
    <div>
      <v-row>
        <vue-snotify></vue-snotify>
        <v-col cols="12" md="12">
          <v-card class="mt-2 mb-4">
            <v-form pt-4 v-model="valid">
              <v-toolbar color="primary">
                <v-row align="center">
                  <v-col class="grow">
                    <span style="color: white"> Crear Permiso</span>
                  </v-col>
                </v-row>
              </v-toolbar>
  
              <v-row class="mt-4 ml-4 mr-4">
                <v-col cols="6">
                  <v-text-field
                    prepend-icon="mdi-key-chain-variant"
                    v-model="name"
                    :rules="nameRules"
                    label="Nombre del Permiso *"
                    type="text"
                  ></v-text-field
                ></v-col>
                <v-col cols="6">
                  <v-text-field
                    prepend-icon="mdi-view-module"
                    v-model="module"
                    :rules="moduleRules"
                    label="Nombre del Módulo *"
                    type="text"
                  ></v-text-field
                ></v-col>
              </v-row>
              <v-row class="mt-4 ml-4 mr-4">
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-eye"
                    v-model="description"
                    label="Observaciones"
                    type="text"
                  ></v-text-field>
                </v-col>
              </v-row>
  
              <v-divider class="mb-3"></v-divider>
              <v-row>
                <v-col cols="12" md="9"></v-col>
                <v-col cols="12" md="3">
                  <v-btn to="/permission" text> Cancelar </v-btn>
  
                  <v-btn color="primary" :disabled="!valid" @click="create()">
                    Aceptar
                  </v-btn></v-col
                >
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </template>
  <script>
  import axios from "axios";
  import VueSnotify from "vue-snotify";
  import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
  
  export default {
    mixins: [SnotifyMixin],
    VueSnotify,
  
    data() {
      return {
        name: "",
        data: {},
        module: "",
        description: "",
        valid: true,
  
        nameRules: [
          (v) => !!v || "El nombre del Permiso es requerido",
          (v) =>
            (v && v.length <= 39) ||
            "El nombre del Permiso debe tener menos de 40 caracteres",
        ],
        moduleRules: [
          (v) => !!v || "El nombre del modulo es requerido",
          (v) =>
            (v && v.length <= 250) ||
            "El nombre del modulo debe tener menos de 250 caracteres",
        ],
      };
    },
  
    methods: {
      create() {
        this.data.name = this.name;
        this.data.module = this.module;
        this.data.description = this.description;
        (this.valid = false),
          axios
            .post("/permission-store", this.data)
            .then(() => {
              this.displayNotification("success", "Éxito", "Permiso registrado");
              setTimeout(() => this.$router.push({ name: "permission" }), 3000);
            })
            .catch((err) => {
              console.log(err, "error");
              this.displayNotification(
                "error",
                "Error",
                "Error al crear el Permiso"
              );
            });
      },
    },
  };
  </script>
  