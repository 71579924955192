<template>
    <v-app>     
     
      <NavigationDrawerView app class="pt-12"></NavigationDrawerView>
      <AppBarView app></AppBarView>
      <!-- Sizes your content based upon application components -->
      <v-main>
    
        <!-- Provides the application the proper gutter -->
        <v-container fluid>   
     
          <router-view></router-view>
        </v-container>
      </v-main>
    
      <v-footer app>
        <!-- -->
      </v-footer>
    </v-app>
    </template>

<script>

import NavigationDrawerView from './NavigationDrawerView.vue'
import AppBarView from './AppBarView.vue'


export default {
  components: {
    NavigationDrawerView,
    AppBarView,
    
  },
}
</script>