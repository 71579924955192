
import { defineStore } from 'pinia'


export const useInstitutionStore = defineStore('institution', {
  state: () => ({
    institution: null,
    loading: false,
    error: null,
  }),
  getters: {
    getInstitution: (state) => state.institution,  
  
  },
  actions: {
    setInstitution(institution) {
      this.institution = institution;
    },
  },
})
