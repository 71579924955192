<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-form pt-4 v-model="valid">
            <v-toolbar color="primary">
              <v-row align="center">
                <v-col class="grow">
                  <span style="color: white"> Registrar Apoderado</span>
                </v-col>
              </v-row>
            </v-toolbar>
            <v-card-text>
              <v-row class="mt-4 ml-4 mr-4">
                <v-col cols="12" md="4">
                  <v-text-field
                    prepend-icon="mdi-card-account-details"
                    :counter="11"
                    v-model="rut"
                    :rules="rutRules"
                    placeholder="12345678-9"
                    label="RUT *"
                    type="text"
                  ></v-text-field
                ></v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    prepend-icon="mdi-account"
                    v-model="name"
                    :rules="nameRules"
                    label="Nombre *"
                    type="text"
                  ></v-text-field
                ></v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    prepend-icon="mdi-alpha-p-box"
                    v-model="last_name"
                    :rules="last_name_Rules"
                    label="Apellido Paterno *"
                    type="text"
                  ></v-text-field
                ></v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    prepend-icon="mdi-alpha-m-box"
                    v-model="mother_last_name"
                    :rules="mother_last_name_Rules"
                    label="Apellido Materno *"
                    type="text"
                  ></v-text-field
                ></v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    prepend-icon="mdi-cellphone"
                    v-model="mobile"
                    :rules="mobileRules"
                    v-mask="'+569########'"
                    label="Teléfono *"
                    type="text"
                  ></v-text-field
                ></v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="region"
                    no-data-text="No hay datos disponibles"
                    :rules="regionRules"
                    :items="regions"
                    item-text="name"
                    item-value="id"
                    prepend-icon="mdi-map"
                    :disabled="itemDisabled"
                    label="Región *"
                    @change="getCommunes()"
                    persistent-hint
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="commune"
                    no-data-text="No hay datos disponibles"
                    :rules="communeRules"
                    :items="communes"
                    item-text="name"
                    item-value="id"
                    prepend-icon="mdi-account-details"
                    :disabled="itemDisabled"
                    label="Comuna *"
                    persistent-hint
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    prepend-icon="mdi-map-marker"
                    v-model="address"
                    :rules="addressRules"
                    label="Dirección *"
                    type="text"
                  ></v-text-field
                ></v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    prepend-icon="mdi-email-fast"
                    v-model="email"
                    :rules="emailRules"
                    label="Correo Electrónico *"
                    type="text"
                  ></v-text-field
                ></v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="9"></v-col>
              <v-col cols="12" md="3">
                <v-btn to="/guardian" class="mr-2" text> Cancelar </v-btn>

                <v-btn color="primary" :disabled="!valid" @click="create()">
                  Aceptar
                </v-btn></v-col
              >
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import UtilitiesMixin from "@/mixins/UtilitiesMixin.vue";

export default {
  mixins: [SnotifyMixin, UtilitiesMixin],
  VueSnotify,

  data() {
    return {
      data: {},
      valid: true,

      //Datos del Funcionario
      numberMask: /^[0-9]*$/,
      rut: "",
      name: "",
      last_name: "",
      mother_last_name: "",
      email: "",
      mobile: "",
      region: "",
      commune: "",
      regions: [],
      communes: [],
      address: "",
      guardians: [],
     /* rutRules: [
        (v) => !!v || "El RUT es requerido",
        (v) =>
          (v && v.length <= 11) || "El RUT debe tener menos de 11 caracteres",
        (v) => this.validaRut(v) || "El RUT no es válido",
      ],
*/
      nameRules: [
        (v) => !!v || "El Nombre es requerido",
        (v) =>
          (v && v.length <= 40) ||
          "El Nombre debe tener menos de 41 caracteres",
        (v) => /^[a-zA-ZáÁéÉíÍóÓúÚñÑ\s']+$/.test(v) || "El Nombre no es válido",
      ],

      last_name_Rules: [
        (v) => !!v || "El Apellido Paterno es requerido",
        (v) =>
          (v && v.length <= 30) ||
          "El Apellido Paterno debe tener menos de 31 caracteres",
        (v) =>
          /^[a-zA-ZáÁéÉíÍóÓúÚñÑ\s']+$/.test(v) ||
          "El Apellido Paterno no es válido",
      ],

      mother_last_name_Rules: [
        (v) => !!v || "El Apellido Materno es requerido",
        (v) =>
          (v && v.length <= 30) ||
          "El Apellido Materno debe tener menos de 31 caracteres",
        (v) =>
          /^[a-zA-ZáÁéÉíÍóÓúÚñÑ\s']+$/.test(v) ||
          "El Apellido Materno no es válido",
      ],

      regionRules: [(v) => !!v || "La Región es requerida"],
      communeRules: [(v) => !!v || "La Comuna es requerida"],

      mobileRules: [
        (v) => !!v || "El Teléfono es requerido",
        (v) => /^\+56(9)([1-9])(\d{7})$/.test(v) || "El Teléfono no es válido",
      ],

      emailRules: [
        (v) => !!v || "El Correo Electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico no es válido",
      ],
      addressRules: [
        (v) => !!v || "La Dirección es requerida",
        (v) =>
          (v && v.length <= 200) ||
          "La Dirección debe tener menos de 201 caracteres",
      ],
    };
  },

  computed: {
   
    rutRules() {
      return [
        v => !!v || "El RUT es requerido",
        v => (v && v.length <= 11) || "El RUT debe tener menos de 12 caracteres",
        v => this.validaRut(v) || 'El RUT no es válido',
        v => !this.guardians.some(guardian => guardian.rut === v) || 'Existe un Apoderado registrado con este RUT'
      ];
    }, 
  },
  mounted() {
    this.action = "Eliminar";
    this.getRegions();
    this.getCommunes();
    
    this.getGuardians();
  },

  methods: {



getGuardians() {
     console.log("carga datos");
     axios
       .get("/guardian-index")
       .then((response) => {
         this.guardians = response.data;
         console.log("imprime response");
         console.log(response);
       })
       .catch((err) => {
         console.log(err, "error");
         this.displayNotification(
           "error",
           "Error",
           "Error al obtener las funciones"
         );
       })
       .finally(() => {
         this.loading = false;
       });
   },

    getFunctions() {
      axios
        .get("/functionOfficial-index")
        .then((response) => {
          this.functions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las funciones"
          );
        });
    },

    getRegions() {
      axios
        .get("/region-index")
        .then((response) => {
          this.regions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las regiones"
          );
        });
    },

    getCommunes() {
      this.data.region_id = this.region;

      axios
        .post("/commune-show-by-region", this.data)
        .then((response) => {
          this.communes = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },

    create() {
      (this.valid = false), (this.data.name = this.name);
      this.data.rut = this.rut;
      this.data.last_name = this.last_name;
      this.data.mother_last_name = this.mother_last_name;
      this.data.email = this.email;
      this.data.mobile = this.mobile;
      this.data.region_id = this.region;
      this.data.commune_id = this.commune;
      this.data.address = this.address;
      axios
        .post("/guardian-store", this.data)
        .then(() => {
          this.displayNotification("success", "Éxito", "Apoderado registrado");
          setTimeout(() => this.$router.push({ name: "guardian" }), 3000);
        })
        .catch((err) => {
          console.log(err, "error");
          if (err.response.status == 409) {
            this.displayNotification("error", "Error", err.response.data);
          } else {
            this.displayNotification(
              "error",
              "Error",
              "Error al crear el apoderado"
            );
          }
        });
    },
  },
};
</script>
