<template>
    <div>
        <v-row>
          <vue-snotify></vue-snotify>
            <v-col cols="12" md="12">
                <v-card class="mt-2 mb-4 ">

                    <v-toolbar color="primary">
                        <v-row align="center">
                            <v-col class="grow ">

                                <span style="color: white;"> Listado de tipos de usuarios </span>
                            </v-col>
                         
                            <v-btn to="/create-user-type" color=" mr-2">
                                <v-icon left>
                                    mdi-door-open
                                </v-icon>
                                Registrar Tipo de Usuario
                            </v-btn>

                        </v-row>
                    </v-toolbar>

                    <v-card-text>
              


                        <v-data-table :headers="headers" :items="typeUsers" :search="search" :footer-props="{
                            itemsPerPageText: 'Filas por página',
                            itemsPerPageAllText: 'todos',
                        }" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">
                         
           <template v-slot:[`item.actions`]="{ item }">   
             
            <v-tooltip top>
              <template v-slot:[`activator`]="{ on, attrs }"> 
                <v-btn icon :to="'/update-user-type/' + item.id">
                  <v-icon v-bind="attrs" color="primary" v-on="on">
                    mdi-pencil-box-multiple
                  </v-icon>
                </v-btn>
              </template>

              <span>Modificar</span>
            </v-tooltip>

            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:[`activator`]="{ on, attrs }"> 
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon v-bind="attrs" color="red darken-2" v-on="on">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">      
                <v-card>
                  <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                  <v-card-text>
                    <div class="text-h7 pa-6">
                      ¿Estás seguro que deseas eliminar el tipo de usuario?
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                    <v-btn
                      class="primary"
                      text
                      
                      @click="
                       dialog.value = false;
                        deleteItem(item);
                      "
                      >Aceptar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </template>
            

                        </v-data-table>
                    
                    </v-card-text>

                </v-card>
            </v-col>

            
        </v-row>

    </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
    mixins: [SnotifyMixin],
    VueSnotify,

    data() {
        return {
         
            drawer: true,
            dialog: false,
            search: "",
            cont:0,
            headers: [               
                
                { text: "Acceso", value: "name" },
                { text: "Acciones", value: "actions" },               
            ],

            typeUsers: [],          

        }
    },

    mounted() {
    this.action = "Eliminar" 
    this.getTypeUsers()
  
  },

    methods: {  
      
      deleteItem(item) {       
    
      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/user-type-destroy", request)
        .then(() => {
          this.getTypeUsers()
          this.displayNotification("success", "Éxito", "Tipo de usuario eliminado");
        
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar el tipo de usuario"
          );
        });
    },
    getTypeUsers()
        {
            axios
        .get("/user-type-index")
        .then((response) => {  
          console.log(response.data)
          this.typeUsers = response.data;         
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los tipos de usuarios"
          );
        });
        },

     
    },
};
</script>