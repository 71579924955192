<template>
  <v-navigation-drawer style="padding-top: 0px !important" v-model="drawer" :mini-variant.sync="mini" app>
    <v-list-item class="px-2" active-class="pink--text">
      <v-img src="../assets/logo.png" class="justify-center" max-height="100" max-width="174" style="display: block">
      </v-img>
    </v-list-item>

    <v-divider class="mt-3"></v-divider>
    <v-list-item class="px-2" style="background: #084dac">
      <v-list-item-avatar>
        <v-img :src="getImagePath()"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="white--text">
          <h4>{{ institucionStore.getInstitution.name }}</h4>
          <h5>{{ institucionStore.getInstitution.rut }}</h5>
        </v-list-item-title>
      </v-list-item-content>
      <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>
    <v-divider></v-divider>
    <div>
      <v-list dense>
        <v-list-item-group color="primary" v-if="items.length > 0">
          <v-list-item v-for="item in items" :key="item.title" :to="item.to" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-divider class=""></v-divider>

        <v-list-group :value="false" prepend-icon="mdi mdi-book-clock" no-action v-if="schedules.length > 0">
          <template v-slot:activator>
            <v-list-item-title>Horarios</v-list-item-title>
          </template>
          <v-list-item style="padding-left: 23px" v-for="item in schedules" :key="item.title" :to="item.to" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider class=""></v-divider>

        <v-list-group :value="false" prepend-icon="mdi-chart-areaspline-variant" no-action v-if="informs.length > 0">
          <template v-slot:activator>
            <v-list-item-title>Informes</v-list-item-title>
          </template>
          <v-list-item style="padding-left: 23px" v-for="item in informs" :key="item.title" :to="item.to" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-list-group :value="false" prepend-icon="mdi-cog" no-action v-if="admin.length > 0">
          <template v-slot:activator>
            <v-list-item-title>Administración</v-list-item-title>
          </template>
          <v-list-item style="padding-left: 23px" v-for="item in admin" :key="item.title" :to="item.to" link>
            <v-list-item-icon>
              <v-icon>{{ item?.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item?.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-list-group :value="false" prepend-icon="mdi-cog-clockwise" no-action v-if="maintainers.length > 0">
          <template v-slot:activator>
            <v-list-item-title>Mantenedores</v-list-item-title>
          </template>
          <div>
            <v-list-item class="" v-for="item in maintainers" :key="item.title" style="padding-left: 23px" :to="item.to"
              link>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-group>
        <v-divider class=""></v-divider>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import axios from "axios";
import { useUserStore } from "@/store/user";
import { useInstitutionStore } from "@/store/institution";

export default {
  setup() {
    const store = useUserStore();
    const institucionStore = useInstitutionStore();

    return { store, institucionStore };
  },
  data() {
    return {
      url: "",
      drawer: true,
      permissions: [],
      valor: true,
      maintainers: [
        {
          title: "Accesos",
          icon: "mdi-door-open",
          to: "/access",
          permission: "permission-maintainers",
        },
        {
          icon: "mdi-swap-horizontal",
          title: "Configuración de Eventos",
          to: "/event",
          permission: "permission-maintainers",
        },
        {
          title: "Funciones",
          icon: "mdi-sitemap",
          to: "/function",
          permission: "permission-maintainers",
        },
        {
          title: "Regiones",
          icon: "mdi-map",
          to: "/region",
          permission: "permission-maintainers",
        },
        {
          title: "Comunas",
          icon: "mdi-map-marker",
          to: "/commune",
          permission: "permission-maintainers",
        },
        {
          title: "Estados de Credenciales",
          icon: "mdi-state-machine",
          to: "/status",
          permission: "permission-maintainers",
        },
        {
          title: "Tipos de usuarios",
          icon: "mdi-account-group",
          to: "/user-type",
          permission: "permission-maintainers",
        },
      ],

      informs: [
        {
          title: "Cursos",
          icon: "mdi-alpha-c-circle",
          to: "/inform-grade",
          permission: "permission-informs",
        },
        {
          title: "Credenciales por curso",
          icon: "mdi-alpha-c-circle",
          to: "/inform-credendential-grade",
          permission: "permission-informs",
        },
        {
          title: "Duplicidad de Credenciales",
          icon: "mdi-credit-card-lock",
          to: "/inform-duplicated-credendential",
          permission: "permission-informs",
        },
        {
          title: "Accesos por RUT",
          icon: "mdi-card-account-details",
          to: "/inform-by-rut",
          permission: "permission-informs",
        },
        {
          title: "Accesos retrasados de alumnos",
          icon: "mdi-account-group",
          to: "/inform-student-tardies",
          permission: "permission-informs",
        },
        {
          title: "Accesos retrasados por Cursos",
          icon: "mdi-alpha-c-circle",
          to: "/inform-student-tardies-grade",
          permission: "permission-informs",
        },
        {
          title: "Accesos por fecha",
          icon: "mdi-chart-timeline",
          to: "inform-by-date",
          permission: "permission-informs",
        },
      ],

      admin: [
        {
          title: "Roles",
          icon: "mdi-file-key",
          to: "/role",
          permission: "permission-admin",
        },
        {
          title: "Permisos",
          icon: "mdi-key-chain-variant",
          to: "/permission",
          permission: "permission-admin",
        },
        {
          title: "Asignar permisos a roles",
          icon: "mdi-key-chain-variant",
          to: "/permission-role",
          permission: "permission-admin",
        },
        {
          title: "Asignar roles a usuarios",
          icon: "mdi-key-chain-variant",
          to: "/role-user",
          permission: "permission-admin",
        },
      ],

      schedules: [
        {
          title: "Horario de Curso",
          icon: "mdi-calendar-collapse-horizontal",
          to: "/schedule",
          permission: "permission-schedule",
        },
        {
          title: "Horario de Institución",
          icon: "mdi mdi-store-clock",
          to: "/create-schedule-institution",
          permission: "permission-schedule",
        },
      ],

      items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          to: "/home",
          permission: "permission-dash",
        },
        {
          title: "Control de Acceso",
          icon: "mdi-shield-lock",
          to: "/access-control",
          permission: "control-access",
        },
        {
          title: "Institución",
          icon: "mdi-school",
          to: "/institution",
          permission: "permission-institution",
        },
        {
          title: "Alumnos",
          icon: "mdi-account-group",
          to: "/student",
          permission: "permission-student",
        },
        {
          title: "Apoderados",
          icon: "mdi mdi-account-child",
          to: "/guardian",
          permission: "permission-student",
        },
        {
          title: "Funcionarios (as)",
          icon: "mdi-account-tie",
          to: "/official",
          permission: "permission-official",
        },
        {
          title: "Visitantes",
          icon: "mdi-account-arrow-right",
          to: "/visit",
          permission: "permission-visit",
        },
        {
          title: "Cursos",
          icon: "mdi-alpha-c-circle",
          to: "/grade",
          permission: "permission-grade",
        },

        {
          title: "Credenciales",
          icon: "mdi-card-account-details",
          to: "/credential",
          permission: "permission-credential",
        },
      ],

      mini: true,
    };
  },

  created() { },
  mounted() {
    // this.getPermissions();
    this.updateMaintainers();
    this.fetchInstitution();
  },

  methods: {

    getImagePath() {
      const uniqueParam = new Date().getTime(); // o un valor único generado de alguna otra manera

      let url = axios.defaults.baseURL.split("api/")[0] + `image/logo.png?timestamp=${uniqueParam}`
      return url
    },

    fetchInstitution() {
      axios
        .get("/institution-index")
        .then((response) => {
          this.institucionStore.setInstitution(response.data[0]);
          this.url = axios.defaults.baseURL;
          console.log("url de institucion");
          console.log(this.url);
          this.url =
            axios.defaults.baseURL.split("api/")[0] +
            this.institucionStore.getInstitution.image;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al cargar datos de la institución"
          );
        });
    },

    updateMaintainers() {
      console.log("entra a updateMaintainers");
      const permissionsStorage = window.localStorage.getItem("permissions");
      if (permissionsStorage) {
        this.permissions = permissionsStorage.split(",");
      }
      /*Menu Mantenedores*/
      const menuMaintainers = this.maintainers.filter((m) =>
        this.permissions.includes(m.permission)
      );
      this.maintainers = menuMaintainers;

      /*Menu Admim*/
      const menuAdmin = this.admin.filter((m) =>
        this.permissions.includes(m.permission)
      );
      this.admin = menuAdmin;

      /*Menu Admim*/
      const menuInforms = this.informs.filter((m) =>
        this.permissions.includes(m.permission)
      );
      this.informs = menuInforms;

      /*Menu Items*/
      const menuItems = this.items.filter((m) =>
        this.permissions.includes(m.permission)
      );
      this.items = menuItems;
    },
    getPermissions() {
      let request = {
        token: this.store.token,
      };
      axios
        .post("/show-user-by-token", request)
        .then((response) => {
          console.log("Estos son los permisos");
          console.log(response.data.permissions);

          this.store.setPermissions(response.data.permissions);

          this.permissions = response.data.permissions;
          console.log("Permisos " + this.permissions);
          console.log("Permisos " + this.permissions[0]);
          if (this.permissions.length == 0) {
            this.displayNotification(
              "error",
              "Error",
              "Este usuario no posee permisos en la Aplicación "
            );
          } else {
            this.$router.push("/");
          }
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las regiones"
          );
        });
    },
  },
};
</script>
