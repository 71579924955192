<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-toolbar color="primary">
            <v-row align="center">
              <v-col class="grow">
                <span style="color: white"> Listado de Credenciales</span>
              </v-col>
              <v-btn to="/upload-image-credential" color="mr-2">
                <v-icon left> mdi mdi-file-image-plus </v-icon>
                Cargar Imagenes
              </v-btn>
              <v-btn to="/create-credential" color=" mr-2">
                <v-icon left> mdi-account-multiple-plus </v-icon>
                Registrar credencial
              </v-btn>
            </v-row>
          </v-toolbar>

          <v-card-text>
            <v-row class="mt-2">
              <v-col cols="4">
                <v-select
                  v-model="typeUser"
                  return-object
                  :items="typeUsers"
                  item-text="name"
                  item-value="value"
                  label="Seleccione el tipo de persona"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-btn class="mt-4" color="primary" @click="getCredencials()">
                  Buscar
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6"></v-col>
              <v-col cols="5" class="ml-10">
                <v-text-field
                  v-model="search"
                  prepend-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="credencials"
              :search="search"
              :footer-props="{
                itemsPerPageText: 'Filas por página',
                itemsPerPageAllText: 'todos',
              }"
              no-data-text="No hay datos disponibles"
              no-results-text="No hay datos disponibles"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:[`activator`]="{ on, attrs }">
                    <v-btn icon :to="'/update-credential/' + item.id">
                      <v-icon v-bind="attrs" color="primary" v-on="on">
                        mdi-pencil-box-multiple
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Aceptar</span>
                </v-tooltip>

                <v-dialog transition="dialog-bottom-transition" max-width="600">
                  <template v-slot:[`activator`]="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon v-bind="attrs" color="red darken-2" v-on="on">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                      <v-card-text>
                        <div class="text-h7 pa-6">
                          ¿Estás seguro que deseas eliminar la credencial?
                        </div>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="dialog.value = false"
                          >Cancelar</v-btn
                        >
                        <v-btn
                          class="primary"
                          text
                          @click="
                            dialog.value = false;
                            deleteItem(item);
                          "
                          >Aceptar</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
      drawer: true,
      dialog: false,
      search: "",
      cont: 0,
      typeUser: "",
      /* $table->string("token",100);
            
          
            $table->unsignedBigInteger("status_id");
            $table->foreign("status_id")*/

      headers: [
        { text: "RUT", value: "rut" },
        { text: "Nombre", value: "name" },
        { text: "Fecha Inicial", value: "start_date" },
        { text: "Fecha Final", value: "end_date" },
        { text: "Estado", value: "status_name" },
        { text: "Tipo de Credencial", value: "type_credencial" },
        { text: "Acciones", value: "actions" },
      ],

      state: "",
      credencials: [],
      status: [],
      credencial_types: [],
      persons: [],
      typeUsers: [
        {
          name: "Alumno(a)",
          value: 1,
        },
        {
          name: "Funcionario(a)",
          value: 2,
        },
        {
          name: "Visitante",
          value: 3,
        },
      ],
    };
  },

  mounted() {
    this.action = "Eliminar";
    this.getCredencialTypes();
    this.getStatus();
  },

  methods: {
    getStatusById(status_id) {
      let state = this.status.find((s) => s.id == status_id);
      return state.name;
    },
    getCredencialTypeById(credencial_type_id) {
      let credencial_type = this.credencial_types.find(
        (c) => c.id == credencial_type_id
      );
      return credencial_type.name;
    },
    getNameByRut(rut) {
      let person = this.persons.find((p) => p.rut == rut);
      return (
        person.name + " " + person.last_name + " " + person.mother_last_name
      );
    },

    getCredencials() {
      let request = {
        user_type: this.typeUser.value,
      };
      axios
        .post("/credential-by-type-person", request)
        .then((response) => {
          this.credencials = [];
          switch (this.typeUser.value) {
            case 1:
              //Student
              console.log('entrar a buscar credenciales de estudiante');
              this.returns = response.data.credentials;
              console.log(this.returns);
              this.returns.forEach((element) => {
                this.credencials.push({
                  id: element.id,
                  rut: element.rut,
                  name:
                    element.student.name +
                    " " +
                    element.student.last_name +
                    " " +
                    element.student.mother_last_name,
                  start_date: this.formatDate(element.start_date),
                  end_date: this.formatDate(element.end_date),
                  status_name: element.status.name,
                  type_credencial: element.credencial_type.name,
                });
              });
              break;
            case 2:
              //Official
              this.returns = response.data.credentials;
              this.returns.forEach((element) => {
                this.credencials.push({
                  id: element.id,
                  rut: element.rut,
                  name:
                    element.official.name +
                    " " +
                    element.official.last_name +
                    " " +
                    element.official.mother_last_name,
                  start_date: this.formatDate(element.start_date),
                  end_date: this.formatDate(element.end_date),
                  status_name: element.status.name,
                  type_credencial: element.credencial_type.name,
                });
              });
              break;
            case 3:
              //Visit
              this.returns = response.data.credentials;
              this.returns.forEach((element) => {
                this.credencials.push({
                  id: element.id,
                  rut: element.rut,
                  name:
                    element.visit.name +
                    " " +
                    element.visit.last_name +
                    " " +
                    element.visit.mother_last_name,
                  start_date: this.formatDate(element.start_date),
                  end_date: this.formatDate(element.end_date),
                  status_name: element.status.name,
                  type_credencial: element.credencial_type.name,
                });
              });
              break;
          }
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las credenciales"
          );
        });
    },
    formatDate(date) {
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    getStatus() {
      axios
        .get("/status-index")
        .then((response) => {
          this.status = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los estados"
          );
        });
    },

    getCredencialTypes() {
      axios
        .get("/credential-type-index")
        .then((response) => {
          this.credencial_types = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los estados"
          );
        });
    },

    deleteItem(item) {
      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/credential-destroy", request)
        .then(() => {
          this.displayNotification("success", "Éxito", "Credencial Eliminada");
          this.getCredencials();
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar la credencial"
          );
        });
    },
  },
};
</script>
