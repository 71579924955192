<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-form pt-4 v-model="valid">
            <v-toolbar color="primary">
              <v-row align="center">
                <v-col class="grow">
                  <span style="color: white"> Crear Rol</span>
                </v-col>
              </v-row>
            </v-toolbar>

            <v-row class="mt-4 ml-4">
              <v-col cols="12" md="4">
                <v-text-field
                  prepend-icon="mdi-file-key"
                  v-model="name"
                  :rules="nameRules"
                  label="Nombre del Rol *"
                  type="text"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="9"></v-col>
              <v-col cols="12" md="3">
                <v-btn to="/role" text> Cancelar </v-btn>

                <v-btn color="primary" :disabled="!valid" @click="create()">
                  Aceptar
                </v-btn></v-col
              >
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
      name: "",
      data: {},
      valid: true,
      permissions: [],
      permissions_send: [],
      nameRules: [
        (v) => !!v || "El nombre del Rol es requerido",
        (v) =>
          (v && v.length <= 19) ||
          "El nombre del Rol debe tener menos de 20 caracteres",
      ],
    };
  },
  mounted() {
    this.getPermissions();
  },
  methods: {
    create() {
      this.data.name = this.name;
      (this.valid = false),
        axios
          .post("/role-store", this.data)
          .then(() => {
            this.displayNotification("success", "Éxito", "Rol registrado");
            setTimeout(() => this.$router.push({ name: "role" }), 3000);
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification("error", "Error", "Error al crear el Rol");
          });
    },
    getPermissions() {
      axios
        .get("/permission-index")
        .then((response) => {
          console.log(response.data);
          this.permissions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los Permisos"
          );
        });
    },
  },
};
</script>
