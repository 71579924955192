<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-toolbar color="primary">
            <v-row align="center">
              <v-col class="grow">
                <span style="color: white"> Credenciales Duplicadas</span>
              </v-col>

              <v-btn @click="exportToExcel" color=" mr-2">
                <v-icon left> mdi-microsoft-excel </v-icon>
                Exportar Excel
              </v-btn>

              <v-btn @click="generateReport" color=" mr-2">
                <v-icon left> mdi-file-pdf-box </v-icon>
                Exportar PDF
              </v-btn>
            </v-row>
          </v-toolbar>

          <v-card-text>
            <v-row class="mt-2">
              <v-col cols="12" md="4">
                <v-select v-model="typeUser" return-object :items="typeUsers" item-text="name" item-value="value"
                  label="Seleccione el tipo de persona">
                </v-select>
              </v-col>
              <v-col cols="12" md="2" class="mt-2">
                <v-btn color="primary" @click="getCredencials()">
                  Buscar
                </v-btn>
              </v-col>
            </v-row>
            <v-data-table :headers="headers" :items="credencials" :search="search" :footer-props="{
              itemsPerPageText: 'Filas por página',
              itemsPerPageAllText: 'todos',
            }" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">
              <template v-slot:[`item.name`]="{ item }">
                <span>{{ getNameByRut(item.rut) }}</span>
              </template>
              <template v-slot:[`item.credencial_type`]="{ item }">
                <span v-if="item.credencial_type_id == 1">Alumno(a)</span>
                <span v-if="item.credencial_type_id == 2">Funcionario(a)</span>
                <span v-if="item.credencial_type_id == 3">Visitante</span>
              </template>
              <template v-slot:[`item.status_id`]="{ item }">
                <span>{{ getNameStatusById(item.status_id) }}</span>
              </template>
              <template v-slot:[`item.date`]="{ item }">
                <span>{{ formatDate(item.updated_at) }}</span>
              </template>

              <template v-slot:[`item.time`]="{ item }">
                <span>{{ formatTime(item.updated_at) }}</span>
              </template>

            </v-data-table>
            <div id="app" ref="testHtml">
              <vue-html2pdf :float-layout="true" :enable-download="true" :preview-modal="false"
                :paginate-elements-by-height="1400" filename="reporte" :pdf-quality="2" :manual-pagination="false"
                pdf-format="a4" @hasStartedGeneration="hasStartedGeneration()" @hasGenerated="hasGenerated($event)"
                ref="html2Pdf">
                <div slot="pdf-content" class="pl-10 pt-10">
                  <br /><br /><br />

                  <v-row>
                    <v-col cols="1"></v-col>
                    <v-col cols="2">
                      <img src="@/assets/logo.png" height="50px" width="200px" alt="Imagen de ejemplo">
                    </v-col>
                    <v-col cols="4"></v-col>
                    <v-col cols="5">
                      <v-list-item class="px-2">
                        <v-list-item-avatar>
                          <img :src="profilepic"
                         height="60px" width="60px" alt="Logo Institución"> </v-list-item-avatar>
                        <v-list-item-content style="padding-left:5px">
                          <v-list-item-title>
                            <h4>{{ institucionStore.getInstitution.name }}</h4>
                            <h5>{{ institucionStore.getInstitution.rut }}</h5>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                  <v-row style="text-align:center">
                    <v-col cols="12"><strong>REPORTE DE CREDENCIALES DUPLICADAS</strong>
                    </v-col>
                  </v-row>

                  
                  <v-row>
                    <v-col cols="12">
                      <v-data-table style="text-align: center; width: 100%;padding-left: 20px;padding-right: 20px;"
                        :headers="headers" :items="credencials" :search="search" hide-default-footer :footer-props="{
                          itemsPerPageText: 'Filas por página',
                          itemsPerPageAllText: 'todos',
                        }" ref="dataTable" no-data-text="No hay datos disponibles"
                        no-results-text="No hay datos disponibles">
                        <template v-slot:[`item.name`]="{ item }">
                          <span>{{ getNameByRut(item.rut) }}</span>
                        </template>
                        <template v-slot:[`item.credencial_type`]="{ item }">
                          <span v-if="item.credencial_type_id == 1">Alumno(a)</span>
                          <span v-if="item.credencial_type_id == 2">Funcionario(a)</span>
                          <span v-if="item.credencial_type_id == 3">Visitante</span>
                        </template>
                        <template v-slot:[`item.status_id`]="{ item }">
                          <span>{{ getNameStatusById(item.status_id) }}</span>
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                          <span>{{ formatDate(item.updated_at) }}</span>
                        </template>

                        <template v-slot:[`item.time`]="{ item }">
                          <span>{{ formatTime(item.updated_at) }}</span>
                        </template>
                      </v-data-table>
                    </v-col>


                  </v-row>
                </div>
              </vue-html2pdf>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import html2pdf from "html2pdf.js";
import VueHtml2pdf from "vue-html2pdf";
import * as XLSX from "xlsx";
import { useUserStore } from "@/store/user";
import { useInstitutionStore } from "@/store/institution";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,
  components: {
    VueHtml2pdf,
  },
  setup() {
    const store = useUserStore();
    const institucionStore = useInstitutionStore();

    return { store, institucionStore };
  },

  data() {
    return {
      profilepic:"",
      visible: false,
      drawer: true,
      dialog: false,
      search: "",
      cont: 0,
      typeUser: "",
      show: true,
      /* $table->string("token",100);


            $table->unsignedBigInteger("status_id");
            $table->foreign("status_id")*/

      headers: [
        { text: "RUT", value: "rut" },
        { text: "Nombre", value: "name" },
        { text: "Tipo de Credencial", value: "credencial_type" },
        { text: "Fecha Duplicidad", value: "date" },
        { text: "Hora Duplicidad", value: "time" },
      ],



      state: "",
      credencials: [],
      status: [],
      credencial_types: [],
      persons: [],
      typeUsers: [
        {
          name: "Alumno(a)",
          value: 1,
        },
        {
          name: "Funcionario(a)",
          value: 2,
        },
        {
          name: "Visitante",
          value: 3,
        },
      ],
    };
  },

  mounted() {
    URL.revokeObjectURL(this.profilepic);
    this.getImage() ;
  },

  methods: {
    getImage() {
      axios
        .get("/imagen/logo.png", { responseType: 'arraybuffer' }) // Indicamos que esperamos una respuesta de tipo arraybuffer
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] });
          const blobUrl = URL.createObjectURL(blob);
          this.profilepic=blobUrl;
         
        })
        .catch(error => {
          console.error('Error al cargar la imagen:', error);
        });

        console.log("Finaliza:" +  this.profilepic)
    },

    getImagePath() {
      let url = axios.defaults.baseURL.split("api/")[0] + `image/logo.png`
      return url
    },

    parseDate(date) {
      if (!date) return null;
      const dateAux = new Date(date);
      const [year, month, day] = dateAux.toISOString().slice(0, 10).split("-");
      return `${day}/${month}/${year}`;
    },
    parseTime(date) {
      if (!date) return null;
      const dateAux = new Date(date);
      const [hour, min, sec] = dateAux.toISOString().slice(11, 19).split("-");
      return `${hour}/${min}/${sec}`;
    },

    exportToExcel() {
      const worksheet = XLSX.utils.table_to_sheet(this.$refs.dataTable.$el);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, "students.xlsx");
    },

    saveAsExcelFile(buffer, fileName) {
      const data = new Blob([buffer], { type: "application/octet-stream" });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(data);
      downloadLink.download = fileName;
      downloadLink.click();
    },

    getNameByRut(rut) {
      let person = this.persons.find((p) => p.rut == rut);
      return (
        person.name + " " + person.last_name + " " + person.mother_last_name
      );
    },

    getNameStatusById() {
      return this.status.name;
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    formatDate(date) {
      if (!date) return null;
      const dateAux = new Date(date);
      const [year, month, day] = dateAux.toISOString().slice(0, 10).split("-");
      return `${day}/${month}/${year}`;
    },

    formatTime(date) {
      if (!date) return null;
      const dateAux = new Date(date);
      const [hour, min, sec] = dateAux.toISOString().slice(11, 19).split(":");
      return `${hour}:${min}:${sec}`;
    },

    generateTableContent() {
      const tableRows = [];

      // Agrega las filas de la tabla
      for (let i = 0; i < this.credencials.length; i++) {
        const row = [];

        // Agrega las celdas de la fila
        for (let j = 0; j < this.headers.length; j++) {
          const key = this.headers[j].value;
          const value = this.credencials[i][key];
          row.push(value);
        }

        tableRows.push(`<tr><td>${row.join("</td><td>")}</td></tr>`);
      }

      return `<table><thead><tr><th>${this.headers
        .map((header) => header.text)
        .join("</th><th>")}</th></tr></thead><tbody>${tableRows.join(
          ""
        )}</tbody></table>`;
    },

    exportTableToPdf() {
      const tableContent = this.generateTableContent();
      const element = document.createElement("div");
      element.innerHTML = tableContent;

      html2pdf(element, {
        margin: 1,
        filename: "tabla.pdf",
        image: { type: "jpg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      });
    },

    getStatusById(status_id) {
      let state = this.status.find((s) => s.id == status_id);
      return state.name;
    },
    getCredencialTypeById(credencial_type_id) {
      let credencial_type = this.credencial_types.find(
        (c) => c.id == credencial_type_id
      );
      return credencial_type.name;
    },

    getCredencials() {
      let request = {
        user_type: this.typeUser.value,
      };
      console.log("entra a buscar credenciales");
      axios
        .post("/credential-duplicated", request)
        .then((response) => {
          this.credencials = response.data.credentials;
          console.log('imrpime credenciales');
          console.log(this.credencials);
          this.persons = response.data.persons;
          this.status = response.data.status;

          let filtered = this.credencials.filter(c => {
            return this.persons.some(p => p.rut === c.rut);
          });
          this.credencials = filtered;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las credenciales"
          );
        });
    },

    getStatus() {
      axios
        .get("/status-index")
        .then((response) => {
          this.status = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los estados"
          );
        });
    },

    getCredencialTypes() {
      axios
        .get("/credencial-type-index")
        .then((response) => {
          this.credencial_types = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los estados"
          );
        });
    },

    deleteItem(item) {
      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/credential-destroy", request)
        .then(() => {
          this.displayNotification("success", "Éxito", "Credencial Eliminada");
          this.getCredencials();
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar la credencial"
          );
        });
    },
  },
};
</script>
