<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4 ">
          <v-form pt-4 v-model="valid">
            <v-toolbar color="primary">
              <v-row align="center">
                <v-col class="grow ">
                   <span style="color: white;"> Modificar tipo de usuario</span>
                </v-col>
              </v-row>
            </v-toolbar>
            <v-row class="mt-4 ml-4">
              <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-account-group" v-model="name" :rules="nameRules"
                  label="Tipo de usuario *" type="text"></v-text-field></v-col>
            </v-row>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="9"></v-col>
              <v-col cols="12" md="3">
                <v-btn to="/user-type" text>
                  Cancelar
                </v-btn>

                <v-btn color="primary" :disabled="!valid" @click="update()">
                  Modificar
                </v-btn></v-col>
            </v-row>
          </v-form>
        </v-card>

      </v-col>
    </v-row>

  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
      name: "",
      data: {},
      valid: true,

      nameRules: [
        v => !!v || 'El tipo de usuario es requerido',
        v => (v && v.length <= 20) || 'El tipo de usuario debe tener menos de 21 caracteres',
      ],

    }
  },

  mounted() {

    this.chargeData();
  },

  methods: {

    chargeData() {
      console.log("cargando datos");
      let request = {
        id: this.$route.params.id,
      };
      axios
        .post("/user-type-show", request)
        .then((response) => {
          console.log(response.data);
          this.name = response.data.name;

        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al cargar el tipo de usuario");
        });
    },

    update() {
      console.log("cargando datos");
      this.data.name = this.name;
      this.data.id = this.$route.params.id
      this.valid = false,

        axios
          .post("/user-type-update", this.data)
          .then(() => {
            this.displayNotification(
              "success",
              "Éxito",
              "Tipo de usuario modificado"
            );
            setTimeout(() => this.$router.push({ name: "user-type" }), 3000);
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification(
              "error",
              "Error",
              "Error al modificar el tipo de usuario"
            );
          });
    },



  },
};
</script>