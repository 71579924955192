<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <v-card outlined class="elevation-12">
        
          <v-card-text class="text-center">
            <span class="text-h5 ">Acceso Prohibido! <br> </span>
            <v-icon size="100">mdi-cancel</v-icon>
            
            <p>No tienes acceso a esta funcionalidad.</p>
            <p>Por favor, inicia sesión con una cuenta autorizada para acceder a esta funcionalidad.</p>
       
           

            <v-btn color="primary" to="/login">Iniciar sesión</v-btn>
            <v-btn color="primary" class="ml-2" to="/home">Volver al inicio</v-btn>
         
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ForbiddenVuew'
}
</script>

<style scoped>
.v-card-title {
  background-color: #fff;
}
.v-card {
  width: 100%;
  max-width: 600px;
}

.fill-height {
height: 100vh;
}

.text-center {
text-align: center;
}

.v-icon {
color: #f44336;
margin-bottom: 20px;
}
</style>
