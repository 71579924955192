<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-form pt-4 v-model="valid">
            <v-toolbar color="primary">
              <v-row align="center">
                <v-col class="grow">
                  <span style="color: white"> Modificar Curso</span>
                </v-col>
              </v-row>
            </v-toolbar>
            <v-row class="mt-4 ml-4 mr-4">
              <v-col cols="12" md="3">
                <v-text-field
                  prepend-icon="mdi mdi-numeric"
                  v-model="code"
                  :rules="codeRules"
                  label="Código "
                  type="text"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  prepend-icon="mdi mdi-account-school"
                  v-model="grade"
                  :rules="gradeRules"
                  label="Grado "
                  type="text"
                ></v-text-field
              ></v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  prepend-icon="mdi-alpha-c-circle"
                  v-model="name"
                  :rules="nameRules"
                  label="Nombre del Curso *"
                  type="text"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="official"
                  no-data-text="No hay datos disponibles"
                  :rules="officialRules"
                  v-bind:items="officials"
                  item-value="id"
                  item-text="`${data.item.name}, ${data.item.name}`"
                  prepend-icon="mdi-account-details"
                  :disabled="itemDisabled"
                  label="Profesor(a) *"
                  persistent-hint
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.name }} {{ data.item.last_name }}
                  </template>

                  <template slot="item" slot-scope="data">
                    <v-list-tile-content>
                      <v-list-tile-title
                        v-html="`${data.item.name} ${data.item.last_name}`"
                      >
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="9"></v-col>
              <v-col cols="12" md="3">
                <v-btn to="/grade" text> Cancelar </v-btn>

                <v-btn color="primary" :disabled="!valid" @click="update()">
                  Modificar
                </v-btn></v-col
              >
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
      name: "",
      data: {},
      officials: [],
      official: "",
      valid: true,
      official_id: 0,
      code: "",
      grade: "",

      nameRules: [
        (v) => !!v || "El Nombre del curso es requerido",
        (v) =>
          (v && v.length <= 20) ||
          "El Nombre del curso  debe tener menos de 21 caracteres",
      ],
    };
  },

  mounted() {
    this.chargeData();
  },

  methods: {
    itemSlot({ item }) {
      return `${item.name} - ${item.last_name}`;
    },

    displayText() {
      return "hola";
    },

    getOfficialById(id) {
      return this.officials.find((c) => c.id == id);
    },

    chargeData() {
      console.log("cargando datos");
      let request = {
        id: this.$route.params.id,
      };
      axios
        .post("/grade-show", request)
        .then((response) => {
          this.name = response.data.name;
          this.getOfficialavailableByGrade();
          this.official = parseInt(response.data.id_official);
          this.code = response.data.code;
          this.grade = response.data.grade;
          // this.official=this.getOfficialById(response.data.id_official);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al cargar el curso"
          );
        });
    },

    getOfficialavailableByGrade() {
      axios
        .post("/official-available-by-grade")
        .then((response) => {
          this.officials = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los profesores"
          );
        });
    },

    update() {
      console.log("cargando datos");
      this.data.name = this.name;
      this.data.id = this.$route.params.id;
      this.data.id_official = this.official;
      this.data.grade = this.grade;
      this.data.code = this.code;
      (this.valid = false),
        axios
          .post("/grade-update", this.data)
          .then(() => {
            this.displayNotification("success", "Éxito", "Curso modificado");
            setTimeout(() => this.$router.push({ name: "grade" }), 3000);
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification(
              "error",
              "Error",
              "Error al modificar el curso"
            );
          });
    },
  },
};
</script>
