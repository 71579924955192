<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4 ">
          <v-form pt-4 v-model="valid">
            <v-toolbar color="primary">
              <v-row align="center">
                <v-col class="grow ">
                  <span style="color: white;"> Actualizar Visita</span>
                </v-col>

              </v-row>
            </v-toolbar>

            <v-card-text>
              <v-row class="mt-4 ml-4 mr-4">
                <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-card-account-details" :counter="11"
                    v-model="rut" :rules="rutRules" placeholder="12345678-9" label="RUT *"
                    type="text"></v-text-field></v-col>
                <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-account" v-model="name" :rules="nameRules"
                    label="Nombre *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-alpha-p-box" v-model="last_name"
                    :rules="last_name_Rules" label="Apellido Paterno *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-alpha-m-box" v-model="mother_last_name"
                    :rules="mother_last_name_Rules" label="Apellido Materno *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-cellphone" v-model="mobile"
                    :rules="mobileRules"  v-mask="'+569########'" label="Teléfono *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-email-fast" v-model="email" :rules="emailRules"
                    label="Correo Electrónico *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="12"> <v-text-field prepend-icon="mdi-calendar-account-outline
" v-model="reason_visit" :rules="reasonVisitRules" label="Razón de visita *" type="text"></v-text-field></v-col>






              </v-row>

            </v-card-text>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="9"></v-col>
              <v-col cols="12" md="3">
                <v-btn to="/visit" class="mr-2" text>
                  Cancelar
                </v-btn>

                <v-btn color="primary" :disabled="!valid" @click="update()">
                  Aceptar
                </v-btn></v-col>
            </v-row>
          </v-form>
        </v-card>

      </v-col>
    </v-row>

  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import UtilitiesMixin from '@/mixins/UtilitiesMixin.vue'

export default {
  mixins: [SnotifyMixin, UtilitiesMixin],
  VueSnotify,

  data() {
    return {

      data: {},
      valid: true,

      //Datos de la visita

      rut: "",
      name: "",
      last_name: "",
      reason_visit: "",
      mother_last_name: "",
      email: "",
      mobile: "",



      functionOfficialRules: [
        v => !!v || 'La Función es requerida',
      ],
      rutRules: [
        (v) => !!v || "El RUT es requerido",
        (v) => (v && v.length <= 11) || "El RUT debe tener menos de 12 caracteres",
        (v) => this.validaRut(v) || 'El RUT no es válido'],

      nameRules: [
        (v) => !!v || "El Nombre es requerido",
        (v) =>
          (v && v.length <= 40) ||
          "El Nombre debe tener menos de 41 caracteres",      
         (v) => /^[a-zA-ZáÁéÉíÍóÓúÚñÑ\s']+$/.test(v) || "El Nombre no es válido",],

      last_name_Rules: [
        (v) => !!v || "El Apellido Paterno es requerido",
        (v) =>
          (v && v.length <= 30) ||
          "El Apellido Paterno debe tener menos de 31 caracteres",
          (v) => /^[a-zA-ZáÁéÉíÍóÓúÚñÑ\s']+$/.test(v) || "El Apellido Paterno no es válido",
      ],

      mother_last_name_Rules: [
        (v) => !!v || "El Apellido Materno es requerido",
        (v) =>
          (v && v.length <= 30) ||
          "El Apellido Materno debe tener menos de 31 caracteres",
          (v) => /^[a-zA-ZáÁéÉíÍóÓúÚñÑ\s']+$/.test(v) || "El Apellido Materno no es válido",
      ],

      mobileRules: [
        v => !!v || 'El Teléfono es requerido',
      ],



      emailRules: [
        v => !!v || 'El Correo Electrónico es requerido',
        v => /.+@.+\..+/.test(v) || 'El Correo Electrónico no es válido',
      ],



    }
  },
  mounted() {
    this.action = "Eliminar";
    this.chargeData();
  },


  methods: {

    chargeData() {
      let request = {
        id: this.$route.params.id,
      };
      axios
        .post("/visit-show", request)
        .then((response) => {
          console.log("Respuesta");
          console.log(response);
          this.name = response.data.name
          this.rut = response.data.rut,
            this.name = response.data.name,
            this.last_name = response.data.last_name,
            this.reason_visit = response.data.reason_visit,
            this.mother_last_name = response.data.mother_last_name,
            this.email = response.data.email,
            this.mobile = response.data.mobile

        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error cargando datos del funcionario");
        });
    },
    update() {

      this.valid = false,
        this.data.id = this.$route.params.id;
      this.data.name = this.name;
      this.data.rut = this.rut;
      this.data.last_name = this.last_name;
      this.data.reason_visit = this.reason_visit;
      this.data.mother_last_name = this.mother_last_name;
      this.data.email = this.email;
      this.data.mobile = this.mobile

      axios
        .post("/visit-update", this.data)
        .then(() => {
          this.displayNotification(
            "success",
            "Éxito",
            "Visita modificada"
          );
          setTimeout(() => this.$router.push({ name: "visit" }), 3000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al modificar datos de la visita"
          );
        });
    },



  },
};
</script>