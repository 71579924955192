<template>
   
  <div class="home">
    <vue-snotify></vue-snotify>
    <div class="text-overline ml-2"> Dashboard </div>
    <v-row>
      <v-col cols="3">
        <v-layout align-center justify-center>
          <v-flex>
            <v-card class="elevation-3 rounded-lg">
              <v-toolbar id="bar-login" style="height: 45px;" dark color="light-blue">
              </v-toolbar>

              <v-card class="rounded-lg" style="z-index:2;margin-top: -20px; border:0px">
                <v-card-text>
                  <div>
                    <v-row>
                      <v-col cols="10">
                        <v-row>
                          <v-col cols="12">
                            <spam  class="text-overline"> Total Alumnos
                            <div class="text-h3 font-weight-bold" style="color:#2b2b2d;">
                              {{ total_students }}
                            </div>
                          </spam>
                            <p> <strong>{{ total_students_inside }}</strong> Dentro de la Institución</p>
                          </v-col>                      
                         
                        </v-row>                     
                      </v-col>

                      <v-col cols="2"><v-btn icon color="light-blue">
                          <v-icon>mdi-account-group</v-icon>
                        </v-btn></v-col>
                    </v-row>
                  </div>
                </v-card-text>

              </v-card>

              <v-toolbar dark color="light-blue" style="margin-top: -30px; height: 70px;">

                <div class="mt-8">
                  <v-icon>mdi-update</v-icon>Actualizado recientemente
                </div>
              </v-toolbar>
            </v-card>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col cols="3">
        <v-layout align-center justify-center>
          <v-flex>
            <v-card class="elevation-3 rounded-lg">
              <v-toolbar id="bar-login" style="height: 45px;" dark color="cyan">
              </v-toolbar>

              <v-card class="rounded-lg" style="z-index:2;margin-top: -20px; border:0px">
                <v-card-text>
                  <div class="">
                    <v-row>
                          <v-col cols="10">
                            <spam  class="text-overline"> Total de Funcionarios
                            <div class="text-h3 font-weight-bold" style="color:#2b2b2d;">
                              {{ total_officials }}
                            </div>
                          </spam>
                            <p> <strong> {{ total_officials_inside }}</strong> Dentro de la Institución</p>
                          </v-col>                     
                         
                      
                      <v-col cols="2"><v-btn icon color="cyan">
                          <v-icon>mdi-account-tie</v-icon>
                        </v-btn></v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
              <v-toolbar dark color="cyan" style="margin-top: -30px; height: 70px;">
                <div class="mt-8">
                  <v-icon>mdi-update</v-icon>Actualizado recientemente
                </div>
              </v-toolbar>
            </v-card>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col cols="3">
        <v-layout align-center justify-center>
          <v-flex>
            <v-card class="elevation-3 rounded-lg">
              <v-toolbar id="bar-login" style="height: 45px;" dark color="amber">
              </v-toolbar>

              <v-card class="rounded-lg" style="z-index:2;margin-top: -20px; border:0px">
                <v-card-text>
                  <div>
                    <v-row>
                          <v-col cols="10">
                            <spam  class="text-overline"> Total de Visitantes
                            <div class="text-h3 font-weight-bold" style="color:#2b2b2d;">
                              {{ total_visit }}
                            </div>
                          </spam>
                            <p> <strong>  {{ total_visits_inside }}</strong> Dentro de la Institución</p>
                          </v-col>   
                      <v-col cols="2"><v-btn active icon color="amber">
                          <v-icon>mdi-account-arrow-right</v-icon>
                        </v-btn></v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
              <v-toolbar dark color="amber" style="margin-top: -30px; height: 70px;">
                <div class="mt-8">
                  <v-icon>mdi-update</v-icon>Actualizado recientemente
                </div>
              </v-toolbar>
            </v-card>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col cols="3">
        <v-layout align-center justify-center>
          <v-flex>
            <v-card class="elevation-3 rounded-lg">
              <v-toolbar id="bar-login" style="height: 45px;" dark color="indigo ">
              </v-toolbar>
              <v-card class="rounded-lg" style="z-index:2;margin-top: -20px; border:0px">
                <v-card-text>
                  <div class="">
                    <v-row>
                          <v-col cols="10">
                            <spam  class="text-overline"> Total de Personas
                            <div class="text-h3 font-weight-bold" style="color:#2b2b2d;">
                              {{ total_students + total_officials + total_visit }}
                            </div>
                          </spam>
                            <p> <strong>   {{ total_students_inside + total_officials_inside + total_visits_inside }}</strong> Dentro de la Institución</p>
                          </v-col>   
                     
                      <v-col cols="2"><v-btn icon color="indigo ">
                          <v-icon>mdi-cog</v-icon>
                        </v-btn></v-col>
                    </v-row>
                  </div>


                </v-card-text>

              </v-card>

              <v-toolbar dark color="indigo" style="margin-top: -30px; height: 70px;">

                <div class="mt-8">
                  <v-icon>mdi-update</v-icon>Actualizado recientemente
                </div>
              </v-toolbar>
            </v-card>
          </v-flex>
        </v-layout>
      </v-col>
    </v-row>
    <br><br>
    <template>
  <v-card>
    <v-toolbar
      color="primary"
      dark
     >
      
        <v-tabs
          v-model="tabs"
          
        >
          <v-tab
          >
            Alumnos
          </v-tab>
          <v-tab
          >
            Funcionarios
          </v-tab>

          <v-tab
          >
            Visitantes
          </v-tab>
        </v-tabs>
       <v-spacer></v-spacer>

        <v-dialog v-model="dialog" persistent max-width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="error"  v-bind="attrs" v-on="on">
                          <v-icon left> mdi mdi-exit-run</v-icon> Salida manual
                        </v-btn>
                      </template>
                      <template v-slot:default="dialog">
                        <v-card>
                          <v-toolbar color="warning" dark>Mensaje</v-toolbar>
                          <v-card-text>
                            <div class="text-h7 pa-6">
                             Se aplicará salida manual a las credenciales de las personas seleccionadas ¿Estás seguro?
                            </div>
                          </v-card-text>
                          <v-card-actions class="justify-end">
                            <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                            <v-btn class="primary" text @click="
                              dialog.value = false;
                              outPerson();
                            ">Aceptar</v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
    </v-toolbar>

    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-data-table id="my-table" :headers="headers" show-select v-model="select_student" :items="students_in_credentials "
              :loading="loading" loading-text="Cargando... Por favor espere" :search="search" :footer-props="{
                itemsPerPageText: 'Filas por página',
                itemsPerPageAllText: 'todos',
              }" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">
              
             

            </v-data-table> 
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-data-table id="my-table" :headers2="headers" show-select v-model="select_officials" :items="officials_in_credentials"
              :loading="loading" loading-text="Cargando... Por favor espere" :search="search" :footer-props="{
                itemsPerPageText: 'Filas por página',
                itemsPerPageAllText: 'todos',
              }" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">
             

            </v-data-table> 
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-data-table id="my-table" :headers="headers3" show-select v-model="select_visits" :items="visits_in_credentials"
              :loading="loading" loading-text="Cargando... Por favor espere" :search="search" :footer-props="{
                itemsPerPageText: 'Filas por página',
                itemsPerPageAllText: 'todos',
              }" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">
             

            </v-data-table> 
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

  </div>
</template>


<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  name: 'HomeView',
  mixins: [SnotifyMixin],
  VueSnotify,
  data() {
    return {
//TODOdEY
      loading: true,
      search: "",
      data: {},

headers: [
        { text: "RUT", value: "rut" },
        { text: "Nombre", value: "name" },
        { text: "Apellido Paterno", value: "last_name" },
        { text: "Apellido Materno", value: "mother_last_name" },
        { text: "Curso", value: "grade_name" },
        { text: "Sexo", value: "sex" },
        { text: "Dirección", value: "address" },
        ],

        headers2: [
        { text: "RUT", value: "rut" },
        { text: "Nombre", value: "name" },
        { text: "Apellido Paterno", value: "last_name" },
        { text: "Apellido Materno", value: "mother_last_name" },
        { text: "Sexo", value: "sex" },
        { text: "Dirección", value: "address" },
        ],

        headers3: [
        { text: "RUT", value: "rut" },
        { text: "Nombre", value: "name" },
        { text: "Apellido Paterno", value: "last_name" },
        { text: "Apellido Materno", value: "mother_last_name" },
        { text: "Razon de Visita", value: "reason_visit" },
        { text: "Email", value: "email" },
        ],
      tabs: null,
      total_students: 0,
      total_officials: 0,
      total_visit: 0,
      total_students_inside: 0,
      total_visits_inside: 0,
      total_officials_inside: 0,


      select_student:[],
      select_officials:[],
      select_visits:[],

      students_in_credentials:[],
      officials_in_credentials:[],
      visits_in_credentials:[],
    }
  },

  mounted() {
    this.getStatictic();
  },

  methods: {


    outPerson()
    {
      console.log("estos son los datos que se estan enviando")
      console.log(this.select_student);

      
      console.log('imprime seleccionados');
      this.data.students = this.select_student;
      this.data.officials= this.select_officials;
      this.data.visits= this.select_visits;


      console.log(this.data)

      this.loading = true;
    
      if(this.data.students.length==0 &&this.data.officials.length==0 && this.data.visits.length==0)
      {
        this.displayNotification(
            "warning",
            "Advertencia",
            "Debe seleccionar al menos una persona"
          );
          this.loading = false;
      }
      else
      {

      axios
        .post("/out-person", this.data)
        .then(() => {
          this.select_student = [];
          this.select_officials = [];
          this.select_visits = [];
                    //this.getStudents();
          this.displayNotification(
            "success",
            "éxito",
            "Proceso realizado correctamente"
          );

        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
             "Ha ocurrido en error en el proceso"
          );
        })
        .finally(() => {
          this.loading = false;
          this.getStatictic();
        });
      }
    
    }
    ,

    getStatictic() {
      axios
        .post("/information-total-student-official-visit")
        .then((response) => {
          this.total_students = response.data.total_students;
          this.total_students_inside = response.data.total_students_inside;
          console.log('total_students_inside');
          console.log(this.total_students_inside);
          this.total_officials = response.data.total_officials;
          this.total_officials_inside = response.data.total_officials_inside;
          console.log('total_officials_inside');
          console.log(this.total_officials_inside);
          this.total_visit = response.data.total_visit;
          this.total_visits_inside = response.data.total_visits_inside;
          console.log('total_visits_inside');


           
          console.log("Esto son los datos que necesito");
          this.students_in_credentials= response.data.students_in_credentials;
          this.officials_in_credentials= response.data.officials_in_credentials;
          this.visits_in_credentials= response.data.visits_in_credentials;
          
          console.log(response.data.students_in_credentials);
          console.log(response.data.officials_in_credentials);
          console.log(response.data.visits_in_credentials);

          console.log(this.total_visits_inside);
          console.log(response.data);
          this.loading=false;

        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al cargar datos de la institución"
          );
        });
    },
  }
}

</script>
