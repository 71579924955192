<template>
    <div>
        <v-row>
            <vue-snotify></vue-snotify>
            <v-col cols="12" md="12">
                <v-card class="mt-2 mb-4 ">
                    <v-form pt-4  v-model="valid">
                    <v-toolbar color="primary">
                        <v-row align="center">
                            <v-col class="grow ">

                                <span style="color: white;"> Modificar Acceso</span>
                            </v-col>                        
                           
                        </v-row>
                    </v-toolbar>

                    
                    <v-row class="mt-4 ml-4">
                        <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-door-open" v-model="name" :rules="nameRules"
                         label="Acceso *" type="text"></v-text-field></v-col>                      
                    </v-row>
                    <v-divider class="mb-3"></v-divider>
              <v-row>
                <v-col cols="12" md="9"></v-col>
                <v-col cols="12" md="3">
                <v-btn to="/access" text>
                  Cancelar
                </v-btn>
               
                <v-btn color="primary"  :disabled="!valid" @click="update()">
                   Modificar
                </v-btn></v-col>
              </v-row>
                    </v-form>
                </v-card>

            </v-col>
        </v-row>

    </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
    mixins: [SnotifyMixin],
    VueSnotify,

    data() {
        return {
            name: "",
            data:{},
            valid: true,

        nameRules: [
        v => !!v || 'El Nombre del Acceso es requerido',
        v => (v && v.length <= 20) || 'El Nombre del Acceso  debe tener menos de 21 caracteres',
      ],

        }
    },

    mounted() {
  
  this.chargeData();
},

    methods: {

        chargeData() {
      console.log("cargando datos");
      let request = {
        id: this.$route.params.id,
      };
      axios
        .post("/entrance-show", request)
        .then((response) => {
          console.log(response.data);
          this.name = response.data.name;        
         
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al cargar el acceso");
});
},

        update()
        {  
            console.log("cargando datos");     
            this.data.name =this.name;
            this.data.id = this.$route.params.id
            this.valid= false,

            axios
        .post("/entrance-update", this.data)
        .then(() => {  
            this.displayNotification(
            "success",
            "Éxito",
            "Acceso modificado"
          );  
          setTimeout(() => this.$router.push({ name: "access" }), 3000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al modificar el Acceso"
          );
        });
        },

       
       
    },
};
</script>