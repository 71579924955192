<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-toolbar color="primary">
            <v-row align="center">
              <v-col class="grow">
                <span style="color: white"> Informaci&oacute;n por fecha</span>
              </v-col>

              <v-btn @click="exportToExcel" color=" mr-2">
                <v-icon left> mdi-microsoft-excel </v-icon>
                Exportar Excel
              </v-btn>

              <v-btn @click="generateReport" color=" mr-2">
                <v-icon left> mdi-file-pdf-box </v-icon>
                Exportar PDF
              </v-btn>
            </v-row>
          </v-toolbar>
          <v-card class="mt-3 pl-5">
            <v-row>
              <v-col cols="12" md="2" class="ml-3 mt-3 mb-3">
                <v-select v-model="typePerson" no-data-text="No hay datos disponibles" :items="typesPerson"
                  item-text="value" item-value="value" prepend-icon="mdi mdi-text-account" label="Tipos de personas *"
                  persistent-hint>
                </v-select>
              </v-col>
              <v-col cols="12" md="2" class="mt-4">
                <template>
                  <v-container>
                    <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                      max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field class="mt-0 pt-0" v-model="computedDateFormatted" label="Fecha inicial *"
                          persistent-hint prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="start_date" no-title @input="menu2 = false"></v-date-picker>
                    </v-menu>
                  </v-container>
                </template>
              </v-col>
              <v-col cols="12" md="2" class="mt-4">
                <template>
                  <v-container>
                    <v-menu v-model="menu3" :close-on-content-click="false" transition="scale-transition" offset-y
                      max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field class="mt-0 pt-0" v-model="computedDateEndFormatted" label="Fecha final *"
                          persistent-hint prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="end_date" :rules="dateRules" no-title
                        @input="menu3 = false"></v-date-picker>
                    </v-menu>
                  </v-container>
                </template>
              </v-col>
              <v-col cols="12" md="2" class="ml-3 mt-3">
                <v-menu ref="menu" v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="time_start" label="Hora inicial" prepend-icon="mdi-clock-time-four-outline"
                      readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-time-picker v-model="time_start" format="HH:mm" full-width
                    @click:minute="$refs.menu.save(time_start)"></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2" class="ml-3 mt-3">
                <v-menu ref="menu" v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="time_end" label="Hora final" prepend-icon="mdi-clock-time-four-outline"
                      readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-time-picker v-model="time_end" format="HH:mm" full-width
                    @click:minute="$refs.menu.save(time_end)"></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="1" class="ml-0 mt-5">
                <v-btn color="primary" @click="getInformAccess()">
                  Buscar
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-divider class="mb-3"></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="2">
                <p>
                  <strong class="ml-3 mr-3">Entradas:</strong>{{ this.total_entrances }}
                </p>
              </v-col>
              <v-col cols="12" md="2">
                <p>
                  <strong class="mr-3">Salidas:</strong>{{ this.total_exits }}
                </p>
              </v-col>
            </v-row>


            <v-data-table :key="JSON.stringify(headers)" :headers="headers" :items="registers" :search="search"
              :footer-props="{
                itemsPerPageText: 'Filas por página',
                itemsPerPageAllText: 'todos',
              }" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">
              <template v-slot:[`item.name`]="{ item }">
                <span>{{
                  item.person.name +
                  " " +
                  item.person.last_name +
                  " " +
                  item.person.mother_last_name
                }}</span>
              </template>

              <template v-slot:[`item.date`]="{ item }">
                <span>{{ formatDateComponent(item.date) }}</span>
              </template>
              <template v-slot:[`item.time`]="{ item }">
                <span>{{ formatTimeComponent(item.date) }}</span>
              </template>
         
            </v-data-table>

            <div id="app" ref="testHtml">

              <vue-html2pdf :float-layout="true" :enable-download="true" :preview-modal="false"
                :paginate-elements-by-height="1400" filename="reporte" :pdf-quality="2" :manual-pagination="false"
                pdf-format="a4" @hasStartedGeneration="hasStartedGeneration()" @hasGenerated="hasGenerated($event)"
                ref="html2Pdf">
                <div slot="pdf-content" class="pl-10 pt-10">
                  <br /><br /><br />
                  <v-row>
                    <v-col cols="1"></v-col>
                    <v-col cols="2">
                      <img src="@/assets/logo.png" height="50px" width="200px" alt="Imagen de ejemplo">
                    </v-col>
                    <v-col cols="4"></v-col>
                    <v-col cols="5">
                      <v-list-item class="px-2">
                        <v-list-item-avatar>
                          <img :src="profilepic"
                         height="60px" width="60px" alt="Logo Institución"></v-list-item-avatar>
                        <v-list-item-content style="padding-left:5px">
                          <v-list-item-title>
                            <h4>{{ institucionStore.getInstitution.name }}</h4>
                            <h5>{{ institucionStore.getInstitution.rut }}</h5>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                  <v-row>

                    <v-col cols="4"></v-col>
                    <v-col cols="4" style="text-align: center;">Reporte de accesos </v-col>
                    <v-col cols="4">
                    </v-col>
                    <br /><br />
                    <v-col cols="1"></v-col>
                    <v-col cols="2">
                      <p>Entradas : {{ " " + this.total_entrances }}</p>
                    </v-col>
                    <v-col cols="6"></v-col>
                    <v-col cols="2">
                      <p>Salidas : {{ " " + this.total_exits }}</p>
                    </v-col>
                    <v-col cols="1"></v-col>
                    <hr style="border: 2px solid black; margin: 20px 0;">
                    <v-col cols="1"></v-col>
                    <v-col cols="10">
                      <v-data-table :headers="headers" :items="registers" :search="search" :items-per-page="-1"
                        hide-default-footer :footer-props="{
                          itemsPerPageText: 'Filas por página',
                          itemsPerPageAllText: 'todos',
                        }" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">
                        <template v-slot:[`item.name`]="{ item }">
                          <span>{{
                            item.person.name +
                            " " +
                            item.person.last_name +
                            " " +
                            item.person.mother_last_name
                          }}</span>
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                          <span>{{ formatDateComponent(item.date) }}</span>
                        </template>
                        <template v-slot:[`item.time`]="{ item }">
                          <span>{{ formatTimeComponent(item.date) }}</span>
                        </template>
                      </v-data-table>

                    </v-col>
                    <v-col cols="1"></v-col>
                  </v-row>
                </div>
              </vue-html2pdf>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { useUserStore } from "@/store/user";
import { useInstitutionStore } from "@/store/institution";

import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import html2pdf from "html2pdf.js";
import VueHtml2pdf from "vue-html2pdf";
import * as XLSX from "xlsx";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,
  components: {
    VueHtml2pdf,
  },
  setup() {
    const store = useUserStore();
    const institucionStore = useInstitutionStore();

    return { store, institucionStore };
  },
  data() {
    return {
      profilepic:"",
      time_start: null,
      time_end: null,
      start_date: null,
      menu2: false,
      menu3: false,
      end_date: null,
      verify_date: false,
      modal2: false,
      visible: false,
      drawer: true,
      dialog: false,
      search: "",
      cont: 0,
      access: "",
      accesses: [],
      dates: ["", ""],
      typePerson: "",
      data: {},
      total_entrances: 0,
      total_exits: 0,
      rut: "",
      person: "",


      typesPerson: [
        { value: "Funcionario" },
        { value: "Alumno" },
        { value: "Visita" },
      ],

      headers: [
        { text: "Nombre", value: "name" },
        { text: "RUT", value: "rut" },
        { text: "Acción", value: "actions.name" },
        { text: "Puerta", value: "entrances.name" },
        { text: "Fecha", value: "date" },
        { text: "Hora", value: "time" },
      ],

      state: "",
      actions: [],
      registers: [],
      status: [],
      credencial_types: [],

      rutRules: [
        (v) => !!v || "El RUT es requerido",
        (v) =>
          (v && v.length <= 10) || "El RUT debe tener menos de 11 caracteres",
      ],
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },

    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.start_date);
    },
    computedDateEndFormatted() {
      return this.formatDate(this.end_date);
    },
  },

  mounted() {
    this.action = "Eliminar";
    this.getAccess();
    this.getActions();
    URL.revokeObjectURL(this.profilepic);    
    this.getImage() ;

  },

  methods: {
    getImage() {
      axios
        .get("/imagen/logo.png", { responseType: 'arraybuffer' }) // Indicamos que esperamos una respuesta de tipo arraybuffer
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] });
          const blobUrl = URL.createObjectURL(blob);
          this.profilepic=blobUrl;
         
        })
        .catch(error => {
          console.error('Error al cargar la imagen:', error);
        });

        console.log("Finaliza:" +  this.profilepic)
    },
    getImagePath() {
      let url = axios.defaults.baseURL.split("api/")[0] + `image/logo.png`
      return url
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    formatDateComponent(date) {
      if (!date) return null;

      const [year, month, day] = date.split(" ")[0].split("-");
      return `${day}/${month}/${year}`;
    },
    formatTimeComponent(date) {
      if (!date) return null;

      const [hour, min] = date.split(" ")[1].split(":");
      return `${hour}:${min}`;
    },

    save(date) {
      this.$refs.menu.save(date);
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    exportToExcel() {
      const worksheet = XLSX.utils.table_to_sheet(this.$refs.html2Pdf.$el);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, "report.xlsx");
    },
    generateTableContent() {
      const tableRows = [];

      // Agrega las filas de la tabla
      for (let i = 0; i < this.credencials.length; i++) {
        const row = [];
        // Agrega las celdas de la fila
        for (let j = 0; j < this.headers.length; j++) {
          const key = this.headers[j].value;
          const value = this.credencials[i][key];
          row.push(value);
        }
        tableRows.push(`<tr><td>${row.join("</td><td>")}</td></tr>`);
      }
      return `<table><thead><tr><th>${this.headers
        .map((header) => header.text)
        .join("</th><th>")}</th></tr></thead><tbody>${tableRows.join(
          ""
        )}</tbody></table>`;
    },

    exportTableToPdf() {
      const tableContent = this.generateTableContent();
      const element = document.createElement("div");
      element.innerHTML = tableContent;

      html2pdf(element, {
        margin: 1,
        filename: "tabla.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      });
    },

    getAccessById(access_id) {
      let access = this.accesses.find((a) => a.id === access_id);
      return access.name;
    },
    getActionById(action_id) {
      let action = this.actions.find((a) => a.id === action_id);
      return action.name;
    },

    getActions() {
      axios
        .get("/action-index")
        .then((response) => {
          this.actions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las credenciales"
          );
        });
    },

    getStatus() {
      axios
        .get("/status-index")
        .then((response) => {
          this.status = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los estados"
          );
        });
    },
    getAccess() {
      axios
        .get("/entrance-index")
        .then((response) => {
          this.accesses = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los estados"
          );
        });
    },

    getInformAccess() {
      if (
        this.start_date != null &&
        this.end_date != null &&
        this.start_date > this.end_date
      ) {
        this.displayNotification(
          "error",
          "Error",
          "Lo sentimos no se puede hacer la búsqueda. La fecha inicial debe ser menor a la fecha final."
        );
        return;
      }
      switch (this.typePerson) {
        case "Funcionario":
          console.log("entra a buscar en oficial");
          this.data.startDate = this.start_date;
          this.data.endDate = this.end_date;
          this.data.time_start = this.time_start;
          this.data.time_end = this.time_end;
          axios
            .post("/information-official-by-date", this.data)
            .then((response) => {
              console.log("imprime data");
              console.log(response.data);
              console.log("imprime resultado de busqueda");
              console.log(response.data.result);
              this.registers = response.data.result;
              console.log("imprime entradas");
              console.log(response.data.entradas);
              this.total_entrances = response.data.inputs;
              console.log("imprime salidas");
              console.log(response.data.salidas);
              this.total_exits = response.data.outs;
            })
            .catch((err) => {
              console.log(err, "error");
              this.displayNotification(
                "error",
                "Error",
                "Error al obtener los estados"
              );
            });
          break;
        case "Alumno":
          this.data.startDate = this.start_date;
          this.data.endDate = this.end_date;
          this.data.time_start = this.time_start;
          this.data.time_end = this.time_end;

          axios
            .post("/information-student-by-date", this.data)
            .then((response) => {
              console.log("imprime resultado de busqueda");
              console.log(response.data.result);
              this.registers = response.data.result;
              console.log("imprime entradas");
              console.log(response.data.entradas);
              this.total_entrances = response.data.inputs;
              console.log("imprime salidas");
              console.log(response.data.salidas);
              this.total_exits = response.data.outs;
              //this.person = response.data.person;
            })
            .catch((err) => {
              console.log(err, "error");
              this.displayNotification(
                "error",
                "Error",
                "Error al obtener los estados"
              );
            });
          break;
        case "Visita":
          this.data.startDate = this.start_date;
          this.data.endDate = this.end_date;
          this.data.time_start = this.time_start;
          this.data.time_end = this.time_end;
          axios
            .post("/information-visit-by-date", this.data)
            .then((response) => {
              console.log("imprime resultado de busqueda");
              console.log(response.data.result);
              this.registers = response.data.result;
              console.log("imprime entradas");
              console.log(response.data.entradas);
              this.total_entrances = response.data.inputs;
              console.log("imprime salidas");
              console.log(response.data.salidas);
              this.total_exits = response.data.outs;
            })
            .catch((err) => {
              console.log(err, "error");
              this.displayNotification(
                "error",
                "Error",
                "Error al obtener los estados"
              );
            });
          break;
      }
    },

    deleteItem(item) {
      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/credential-destroy", request)
        .then(() => {
          this.displayNotification("success", "Éxito", "Credencial Eliminada");
          this.getCredencials();
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar la credencial"
          );
        });
    },
  },
};
</script>
