<template>
    <div>
        <v-row>
          <vue-snotify></vue-snotify>
            <v-col cols="12" md="12">
                <v-card class="mt-2 mb-4 ">
                    <v-toolbar color="primary">
                        <v-row align="center">
                          <v-col class="grow ">
                            <span style="color: white;"> Configuración de Eventos </span>
                            </v-col>

                         
                            <v-btn to="/create-event" color=" mr-2">
                                <v-icon left>
                                  mdi-swap-horizontal
                                </v-icon>
                                Registrar Configuración de Evento
                            </v-btn>

                        </v-row>
                    </v-toolbar>

                    <v-card-text>
              


                        <v-data-table :headers="headers" :items="event_configurations" :search="search" :footer-props="{
                            itemsPerPageText: 'Filas por página',
                            itemsPerPageAllText: 'todos',
                        }" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">


<template v-slot:[`item.color_start`]="{ item }">   
             
            
          
                 <v-btn   :color="item.color_start">
                   
                 </v-btn>
               </template>

               <template v-slot:[`item.color_end`]="{ item }">   
             
            
          
             <v-btn   :color="item.color_end">
               
             </v-btn>
           </template>

           <template v-slot:[`item.institution_access`]="{ item }">  
         
                <span>
                  <v-chip v-if="item.institution_access == 1" class="ma-2" color="teal" text-color="white">
                    <v-avatar left>
                      <v-icon>mdi-checkbox-marked-circle</v-icon>
                    </v-avatar>
                    Si
                  </v-chip>

                  <v-chip v-else class="ma-2">
                    <v-avatar left>
                      <v-icon>mdi-close-box</v-icon>
                    </v-avatar>
                    No
                  </v-chip>
                </span>
            
          </template>
                         
           <template v-slot:[`item.actions`]="{ item }">   
             
            <v-tooltip top>
              <template v-slot:[`activator`]="{ on, attrs }"> 
                <v-btn icon :to="'/update-event/' + item.id">
                  <v-icon v-bind="attrs" color="primary" v-on="on">
                    mdi-pencil-box-multiple
                  </v-icon>
                </v-btn>
              </template>

              <span>Modificar</span>
            </v-tooltip>

            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:[`activator`]="{ on, attrs }"> 
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon v-bind="attrs" color="red darken-2" v-on="on">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">      
                <v-card>
                  <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                  <v-card-text>
                    <div class="text-h7 pa-6">
                      ¿Estás seguro que deseas eliminar la Configuración?
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                    <v-btn
                      class="primary"
                      text
                      
                      @click="
                       dialog.value = false;
                        deleteItem(item);
                      "
                      >Aceptar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </template>
            

                        </v-data-table>
                    
                    </v-card-text>

                </v-card>
            </v-col>

            
        </v-row>

    </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
    mixins: [SnotifyMixin],
    VueSnotify,

    data() {
        return {
         
            drawer: true,
            dialog: false,
            search: "",
            cont:0,
            headers: [               
              //  { text: "No", value: "id" },
                { text: "Nombre", value: "name" },
                { text: "Color de Evento de Entrada", value: "color_start" },
                { text: "Color de Evento de Salida", value: "color_end" },                
                { text: "Entrada/Salida de la Institución", value: "institution_access" },
                { text: "Acceso", value: "access.name" },
                { text: "Acciones", value: "actions" },               
            ],

            event_configurations: [],          

        }
    },

    mounted() {
   // this.action = "Eliminar" 
   this.getEventConfigurations()
  
  },

    methods: {  
      
      deleteItem(item) {       
    
      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/event-configuration-destroy", request)
        .then(() => {
          this.getEventConfigurations()
          this.displayNotification("success", "Éxito", "Configuración eliminada");
        
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar la Configuración"
          );
        });
    },
    getEventConfigurations()
        {
          console.log("entra")
            axios
        .get("/event-configuration-index")
        .then((response) => {  
          console.log(response.data)
          this.event_configurations = response.data;         
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las Configuraciones"
          );
        });
        },

     
    },
};
</script>