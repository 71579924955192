<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-toolbar color="primary">
            <v-row align="center">
              <v-col class="grow">
                <span style="color: white">Agregar imágenes de alumnos </span>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-progress-linear
            v-if="visible"
            color="primary"
            indeterminate
            rounded
            height="5"
          ></v-progress-linear>
          <v-card-text>
            <template>
              <v-file-input
                v-validate="'required'"
                ref="fileInput"
                v-model="file"
                name="file"
                accept=".zip"
                label="Seleccione el archivo a importar"
                @change="handleFileUpload"
              ></v-file-input>

              <v-divider class="my-3"></v-divider>
              <v-row>
                <v-col cols="12" md="9"></v-col>
                <v-col cols="12" md="3">
                  <v-btn to="/student" text> Cancelar </v-btn>
                  <v-btn color="primary" :disabled="!valid" @click="uploadFile">
                    Aceptar
                  </v-btn></v-col
                >
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
      visible: false,
      drawer: true,
      dialog: false,
      search: "",
      cont: 0,
      file: "",
      fileNames: [],
      valid: false,
    };
  },

  mounted() {
    this.action = "Eliminar";
  },

  methods: {
    handleFileUpload() {
      this.valid = true;
    },
    uploadFile() {
      let flag = true;
      this.visible = true;
      const formData = new FormData();
      formData.append("file", this.file);
      this.valid = false;
      axios
        .post("/student-image-upload", formData)
        .then((response) => {
          console.log("Respuesta del data de archivo");
          console.log(response);
        })
        .catch(() => {
          this.visible = false;
          flag = false;
          this.displayNotification("error", "Error", "Error al cargar archivo");
        })
        .finally(() => {
          if (flag) {
            this.valid = false;
            this.displayNotification(
              "success",
              "Exito",
              "Se ha cargado el archivo correctamente"
            );
          }
          setTimeout(() => this.$router.push({ name: "student" }), 3000);
        });
    },
  },
};
</script>
