import { defineStore } from "pinia";

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    token: "",
    role: "",
    official: "",
    permissions:[],
    isAuthenticated: false,
    user: null,    
  }),
  actions: {
    setToken(token) {
      this.token = token;
      localStorage.setItem('token', this.token);
      localStorage.setItem('isAuthenticated', true);

    },
    setRole(role) {
      this.role = role;
      localStorage.setItem('role', this.role);
    },
    setOfficial(official) {
      this.official = official;
      localStorage.setItem('official', this.official);
    },
    setPermissions(permissions) {
      this.isAuthenticated = true;
      this.permissions = permissions;
      console.log("Asigna Permisos")
      console.log(permissions)
      localStorage.setItem('permissions', this.permissions);
    },

    logout() {
      this.isAuthenticated = false     
      this.permissions = []
      this.role =""
      this.token=""
      this.official=""

      localStorage.removeItem('role');
      localStorage.removeItem('permissions');
      localStorage.removeItem('token');
      localStorage.removeItem('official');
      localStorage.removeItem('isAuthenticated');
    },
  },
  getters: {
    isAuthenticatedUser: state => state.isAuthenticated,   
    permissionsUser: state => state.permissions,
    hasPermission: state => permission => state.permissions.includes(permission),
  },
});
