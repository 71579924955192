<template>
  <v-app-bar height="60" app>
    <v-row>

      <v-col cols="12" md="10"></v-col>
      <v-col cols="12" md="2">    

<v-menu offset-y>
  <template v-slot:activator="{ attrs, on }">

<v-list-item class="custom-color" v-bind="attrs" v-on="on">
  <v-avatar class="mr-2"
  color="primary"
  size="44"
>
<v-icon dark>
  mdi-account-tie
      </v-icon>
</v-avatar>

        <v-list-item-content>
          <v-list-item-title> {{ nameOfficial }}</v-list-item-title>

          <v-list-item-subtitle> {{ rol }} </v-list-item-subtitle>
        </v-list-item-content>

      </v-list-item>

  </template>

  <v-list>
    <v-list-item v-for="item in items_user_logued" :key="item" to="login">
      <v-list-item-title v-text="item.name"></v-list-item-title>
    </v-list-item>
  </v-list>
</v-menu>
</v-col>


    </v-row>
  </v-app-bar>
</template>
<script>
import axios from "axios";
import { useDoorStore } from '../store/index'

export default {
  setup() {
    const store = useDoorStore()
    return { store }
  },
  data: () => ({



    btns: [
      ['Large', 'lg'],

    ],
    name: "",
    nameOfficial: "",
    rol: "",
    door: { id: 0, name: "Acceso" },
    items: [],
    items_user_logued: [{ id: 2, name: "Salir" }],
  }),

  mounted() {
    this.action = "Eliminar"
    this.getAccess()
    this.getNameOfficial()

  },

  methods: {

    getNameOfficial() {
      
      this.nameOfficial = localStorage.getItem("official")
      this.rol = localStorage.getItem("role")
    },
    updateDoor(door) {
      console.log(door)
      this.store.setDoor(door.id, door.name)
    },
    getAccess() {
      axios
        .get("/entrance-index")
        .then((response) => {
          console.log(response.data)
          this.items = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener los accesos"
          );
        });
    },
  },
}
</script>
<style>
.custom-color {
  background-color: #CFD8DC !important; /* Utiliza cualquier color CSS válido aquí */
  color:#263238 !important; /* Color del texto */
}

</style>