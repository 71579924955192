import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "@/views/LoginView.vue";
import DashboardView from "@/views/DashboardView.vue";
import AccessControlView from "@/views/access-control/AccessControlView.vue";
import StudentView from "@/views/students/StudentView.vue";
import CreateStudentView from "@/views/students/CreateStudentView.vue";
import UpdateStudentView from "@/views/students/UpdateStudentView.vue";
import UploadStudentView from "@/views/students/UploadStudentView.vue";
import ImageUploadStudentView from "@/views/students/UploadImageStudentView.vue";
import GuardianView from "@/views/guardians/GuardianView.vue";
import CreateGuardianView from "@/views/guardians/CreateGuardianView.vue";
import UpdateGuardianView from "@/views/guardians/UpdateGuardianView.vue";
import CommuneView from "@/views/commune/CommuneView.vue";
import CreateCommuneView from "@/views/commune/CreateCommuneView.vue";
import UpdateCommuneView from "@/views/commune/UpdateCommuneView.vue";
import FunctionView from "@/views/functions/FunctionView.vue";
import CreateFunctionView from "@/views/functions/CreateFunctionView.vue";
import UpdateFunctionView from "@/views/functions/UpdateFunctionView.vue";
import OfficialView from "@/views/officials/OfficialView.vue";
import CreateOfficialView from "@/views/officials/CreateOfficialView.vue";
import UpdateOfficialView from "@/views/officials/UpdateOfficialView.vue";
import UploadOfficialView from "@/views/officials/UploadOfficialView.vue";
import StatusView from "@/views/status/StatusView.vue";
import CreateStatusView from "@/views/status/CreateStatusView.vue";
import UpdateStatusView from "@/views/status/UpdateStatusView.vue";
import AccessView from "@/views/access/AccessView.vue";
import CreateAccessView from "@/views/access/CreateAccessView.vue";
import UpdateAccessView from "@/views/access/UpdateAccessView.vue";
import VisitView from "@/views/visits/VisitView.vue";
import CreateVisitView from "@/views/visits/CreateVisitView.vue";
import UpdateVisitView from "@/views/visits/UpdateVisitView.vue";
import ActionView from "@/views/actions/ActionView.vue";
import CreateActionView from "@/views/actions/CreateActionView.vue";
import UpdateActionView from "@/views/actions/UpdateActionView.vue";

import EventView from "@/views/events/EventView.vue";
import CreateEventView from "@/views/events/CreateEventView.vue";
import UpdateEventView from "@/views/events/UpdateEventView.vue";
import CredencialTypeView from "@/views/credencial-type/CredencialTypeView.vue";
import CreateCredencialTypeView from "@/views/credencial-type/CreateCredencialTypeView.vue";
import UpdateCredencialTypeView from "@/views/credencial-type/UpdateCredencialTypeView.vue";
import GateView from "@/views/gate/GateView.vue";
import CreateGateView from "@/views/gate/CreateGateView.vue";
import UpdateGateView from "@/views/gate/UpdateGateView.vue";
import GradeView from "@/views/grades/GradeView.vue";
import CreateGradeView from "@/views/grades/CreateGradeView.vue";
import UpdateGradeView from "@/views/grades/UpdateGradeView.vue";
import InstitutionView from "@/views/institution/InstitutionView.vue";
import CreateInstitutionView from "@/views/institution/CreateInstitutionView.vue";
import UpdateInstitutionView from "@/views/institution/UpdateInstitutionView.vue";
import RegionView from "@/views/region/RegionView.vue";
import CreateRegionView from "@/views/region/CreateRegionView.vue";
import UpdateRegionView from "@/views/region/UpdateRegionView.vue";
import UserTypeView from "@/views/user-type/UserTypeView.vue";
import CreateUserTypeView from "@/views/user-type/CreateUserTypeView.vue";
import UpdateUserTypeView from "@/views/user-type/UpdateUserTypeView.vue";
import CredencialView from "@/views/credencial/CredencialView.vue";
import CreateCredencialView from "@/views/credencial/CreateCredentialView.vue";
import UpdateCredencialView from "@/views/credencial/UpdateCredencialView.vue";
import ScheduleView from "@/views/schedule/ScheduleView.vue";
import CreateScheduleView from "@/views/schedule/CreateScheduleView";
import UpdateScheduleView from "@/views/schedule/UpdateScheduleView";
import ShowScheduleView from "@/views/schedule/ShowScheduleView";
import CreateScheduleInstitutionView from "@/views/schedule/CreateScheduleInstitutionView";
import UpdateScheduleInstitutionView from "@/views/schedule/UpdateScheduleInstitutionView";
import ShowScheduleInstitutionView from "@/views/schedule/ShowScheduleInstitutionView";
import NotFound from "@/views/NotFound.vue";
import Forbidden from "@/views/Forbidden.vue";
import HomeView from "@/views/HomeView.vue";
import RoleView from "@/views/role/RoleView.vue";
import CreateRoleView from "@/views/role/CreateRoleView";
import UpdateRoleView from "@/views/role/UpdateRoleView";
import PermissionRoleView from "@/views/role/AddPermissionToRole";
import AddRoleToUserView from "@/views/role/AddRoleToUser";
import PermissionView from "@/views/permission/PermissionView.vue";
import CreatePermissionView from "@/views/permission/CreatePermissionView";
import UpdatePermissionView from "@/views/permission/UpdatePermissionView";
import CredentialDuplicatedView from "@/views/inform/CredentialDuplicatedView";
import { setActivePinia, createPinia } from "pinia";

import InformGradeView from "@/views/inform/InformGradeView";
import InformByRutView from "@/views/inform/InformByRutView";
import InformStudentTardiesView from "@/views/inform/InformStudentTardiesView";
import InformStudentTardiesGradeView from "@/views/inform/InformStudentTardiesGradeView";
import InformByDateView from "@/views/inform/InformByDateView";
import InformStudentCredentialGradeView from "@/views/inform/InformStudentCredentialGradeView";
import ImageUploadCredentialView from "@/views/credencial/UploadImageCredencialsView.vue";

// eslint-disable-next-line
import { useUserStore } from "@/store/user";

Vue.use(VueRouter);

const routes = [
  { path: "*", component: NotFound },
  { path: "/forbidden", component: Forbidden },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },

  {
    path: "/",
    name: "dashboard",
    component: DashboardView,
    meta: { requiresAuth: true, requiresPermissions: ["permission-dash"] },
    children: [
      {
        path: "/home",
        name: "home",
        component: HomeView,
        meta: { requiresAuth: true, requiresPermissions: ["permission-dash"] },
      },
      {
        path: "action",
        name: "action",
        component: ActionView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "create-action",
        name: "create-action",
        component: CreateActionView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "update-action/:id",
        name: "update-action",
        component: UpdateActionView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },

      {
        path: "event",
        name: "event",
        component: EventView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "create-event",
        name: "create-event",
        component: CreateEventView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "update-event/:id",
        name: "update-event",
        component: UpdateEventView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "institution",
        name: "institution",
        component: InstitutionView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-institution"],
        },
      },
      {
        path: "create-institution",
        name: "create-institution",
        component: CreateInstitutionView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-institution"],
        },
      },
      {
        path: "update-institution/:id",
        name: "update-institution",
        component: UpdateInstitutionView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-institution"],
        },
      },

      {
        path: "gate",
        name: "gate",
        component: GateView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-schedule"],
        },
      },
      {
        path: "create-gate",
        name: "create-gate",
        component: CreateGateView,
        meta: { requiresAuth: true, requiresPermissions: ["create-gate"] },
      },
      {
        path: "update-gate/:id",
        name: "update-gate",
        component: UpdateGateView,
        meta: { requiresAuth: true, requiresPermissions: ["update-gate"] },
      },
      {
        path: "grade",
        name: "grade",
        component: GradeView,
        meta: { requiresAuth: true, requiresPermissions: ["permission-grade"] },
      },
      {
        path: "create-grade",
        name: "create-grade",
        component: CreateGradeView,
        meta: { requiresAuth: true, requiresPermissions: ["permission-grade"] },
      },
      {
        path: "update-grade/:id",
        name: "update-grade",
        component: UpdateGradeView,
        meta: { requiresAuth: true, requiresPermissions: ["permission-grade"] },
      },
      {
        path: "access-control",
        name: "access-control",
        component: AccessControlView,
        meta: { requiresAuth: true, requiresPermissions: ["control-access"] },
      },
      {
        path: "credential",
        name: "credential",
        component: CredencialView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-credential"],
        },
      },
      {
        path: "upload-image-credential",
        name: "upload-image-credential",
        component: ImageUploadCredentialView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-credential"],
        },
       },
      {
        path: "create-credential",
        name: "create-credential",
        component: CreateCredencialView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-credential"],
        },
      },
      {
        path: "update-credential/:id",
        name: "update-credential",
        component: UpdateCredencialView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-credential"],
        },
      },

      {
        path: "credential-type",
        name: "credential-type",
        component: CredencialTypeView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "create-credential-type",
        name: "create-credential-type",
        component: CreateCredencialTypeView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "update-credential-type/:id",
        name: "update-credential-type",
        component: UpdateCredencialTypeView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "user-type",
        name: "user-type",
        component: UserTypeView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "create-user-type",
        name: "create-user-type",
        component: CreateUserTypeView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "update-user-type/:id",
        name: "update-user-type",
        component: UpdateUserTypeView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },

      {
        path: "student",
        name: "student",
        component: StudentView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-student"],
        },
      },
      {
        path: "create-student",
        name: "create-student",
        component: CreateStudentView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-student"],
        },
      },
      {
        path: "update-student/:id",
        name: "update-student",
        component: UpdateStudentView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-student"],
        },
      },

      {
        path: "guardian",
        name: "guardian",
        component: GuardianView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-student"],
        },
      },

      {
        path: "update-guardian/:id",
        name: "update-guardian",
        component: UpdateGuardianView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-student"],
        },
      },

      {
        path: "create-guardian",
        name: "create-guardian",
        component: CreateGuardianView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-student"],
       },
      },

      {
        path: "upload-student",
        name: "upload-student",
        component: UploadStudentView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-student"],
        },
      },
      {
        path: "upload-image-student",
        name: "upload-image-student",
        component: ImageUploadStudentView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-student"],
   },
 },

      {
        path: "upload-official",
        name: "upload-official",
        component: UploadOfficialView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-official"],
        },
      },

      {
        path: "commune",
        name: "commune",
        component: CommuneView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "create-commune",
        name: "create-commune",
        component: CreateCommuneView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "update-commune/:id",
        name: "update-commune",
        component: UpdateCommuneView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },

      {
        path: "function",
        name: "function",
        component: FunctionView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "create-function",
        name: "create-function",
        component: CreateFunctionView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "update-function/:id",
        name: "update-function",
        component: UpdateFunctionView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "official",
        name: "official",
        component: OfficialView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-official"],
        },
      },
      {
        path: "create-official",
        name: "create-official",
        component: CreateOfficialView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-official"],
        },
      },
      {
        path: "update-official/:id",
        name: "update-official",
        component: UpdateOfficialView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-official"],
        },
      },

      {
        path: "status",
        name: "status",
        component: StatusView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "create-status",
        name: "create-status",
        component: CreateStatusView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "update-status/:id",
        name: "update-status",
        component: UpdateStatusView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "access",
        name: "access",
        component: AccessView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "create-access",
        name: "create-access",
        component: CreateAccessView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "update-access/:id",
        name: "update-access",
        component: UpdateAccessView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "visit",
        name: "visit",
        component: VisitView,
        meta: { requiresAuth: true, requiresPermissions: ["permission-visit"] },
      },
      {
        path: "create-visit",
        name: "create-visit",
        component: CreateVisitView,
        meta: { requiresAuth: true, requiresPermissions: ["permission-visit"] },
      },
      {
        path: "update-visit/:id",
        name: "update-visit",
        component: UpdateVisitView,
        meta: { requiresAuth: true, requiresPermissions: ["permission-visit"] },
      },
      {
        path: "region",
        name: "region",
        component: RegionView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "create-region",
        name: "create-region",
        component: CreateRegionView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },
      {
        path: "update-region/:id",
        name: "update-region",
        component: UpdateRegionView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-maintainers"],
        },
      },

      {
        path: "schedule",
        name: "schedule",
        component: ScheduleView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-schedule"],
        },
      },
      {
        path: "create-schedule",
        name: "create-schedule",
        component: CreateScheduleView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-schedule"],
        },
      },

      {
        path: "create-schedule-institution",
        name: "create-schedule-institution",
        component: CreateScheduleInstitutionView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-schedule-institution"],
        },
      },

      {
        path: "update-schedule/:id",
        name: "update-schedule",
        component: UpdateScheduleView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-schedule"],
        },
      },

      {
        path: "update-schedule-institution/:id",
        name: "update-schedule-institution",
        component: UpdateScheduleInstitutionView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-schedule-institution"],
        },
      },

      {
        path: "show-calendar/:id",
        name: "show-calendar",
        component: ShowScheduleView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-schedule"],
        },
      },

      {
        path: "show-calendar-institution/:id",
        name: "show-calendar-institution",
        component: ShowScheduleInstitutionView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-schedule-institution"],
        },
      },

      {
        path: "role",
        name: "role",
        component: RoleView,
        meta: { requiresAuth: true, requiresPermissions: ["permission-admin"] },
      },
      {
        path: "create-role",
        name: "create-role",
        component: CreateRoleView,
        meta: { requiresAuth: true, requiresPermissions: ["permission-admin"] },
      },
      {
        path: "update-role/:id",
        name: "update-role",
        component: UpdateRoleView,
        meta: { requiresAuth: true, requiresPermissions: ["permission-admin"] },
      },
      {
        path: "permission-role",
        name: "permission-role",
        component: PermissionRoleView,
        meta: { requiresAuth: true, requiresPermissions: ["permission-admin"] },
      },
      {
        path: "role-user",
        name: "role-user",
        component: AddRoleToUserView,
        meta: { requiresAuth: true, requiresPermissions: ["permission-admin"] },
      },

      {
        path: "permission",
        name: "permission",
        component: PermissionView,
        meta: { requiresAuth: true, requiresPermissions: ["permission-admin"] },
      },
      {
        path: "create-permission",
        name: "create-permission",
        component: CreatePermissionView,
        meta: { requiresAuth: true, requiresPermissions: ["permission-admin"] },
      },
      {
        path: "update-permission/:id",
        name: "update-permission",
        component: UpdatePermissionView,
        meta: { requiresAuth: true, requiresPermissions: ["permission-admin"] },
      },

      // TODO permisos de los informes que esta viendo ranses
      {
        path: "inform-duplicated-credendential",
        name: "inform-duplicated-credendential",
        component: CredentialDuplicatedView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-informs"],
        },
      },
      {
        path: "inform-credendential-grade",
        name: "inform-credendential-grade",
        component: InformStudentCredentialGradeView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-informs"],
        },
      },

      {
        path: "/inform-grade",
        name: "inform-grade",
        component: InformGradeView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-informs"],
        },
      },

      {
        path: "/inform-by-rut",
        name: "inform-by-rut",
        component: InformByRutView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-informs"],
        },
      },
      {
        path: "/inform-student-tardies",
        name: "inform-student-tardies",
        component: InformStudentTardiesView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-informs"],
        },
      },
      {
        path: "/inform-student-tardies-grade",
        name: "inform-student-tardies-grade",
        component: InformStudentTardiesGradeView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-informs"],
        },
      },

      {
        path: "/inform-by-date",
        name: "inform-by-date",
        component: InformByDateView,
        meta: {
          requiresAuth: true,
          requiresPermissions: ["permission-informs"],
        },
      },
    ],
  },
];
/*
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
*/

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  setActivePinia(createPinia());
  const store = useUserStore();

  // Requiere autenticación
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    console.log("Esta entrando aqui");
    // El usuario no está autenticado

    // const lastRouteName = localStorage.getItem('permissions')

    console.log(store.isAuthenticatedUser);
    console.log(localStorage.getItem("isAuthenticated"));
    if (
      localStorage.getItem("isAuthenticated") == null ||
      !localStorage.getItem("isAuthenticated")
    ) {
      console.log("aqui entra tambien");
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
      return;
    }
  }
  // Requiere permisos
  if (to.matched.some((record) => record.meta.requiresPermissions)) {
    const permissions = to.meta.requiresPermissions;
    // El usuario no tiene los permisos requeridos

    const userPermissions = localStorage.getItem("permissions").split(",");

    const hasPermissions = permissions.every((permission) =>
      userPermissions.includes(permission)
    );

    if (hasPermissions) {
      next();
    } else {
      next("/forbidden");
      return;
    }
  }

  next();
});

export default router;
