import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'
import es from 'vuetify/es5/locale/es'
Vue.use(Vuetify);

Vue.component('v-calendar', {
  methods: {
    changeLocale () {
      this.$vuetify.lang.current = 'es'
    },
  },
})

export default new Vuetify({
    theme: {
      themes: {
        light: {
          primary: colors.blue.darken2, // #E53935
          secondary: colors.red.lighten4, // #FFCDD2
          accent: colors.indigo.base, // #3F51B5
        },
      },

      lang: {
        locales: { es },
        current: 'es',
      },
    },
  })
