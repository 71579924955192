<template>
    <div>
        <v-row>
            <vue-snotify></vue-snotify>
            <v-col cols="12" md="12">
                <v-card class="mt-2 mb-4 ">
                    <v-form pt-4 v-model="valid">
                        <v-toolbar color="primary">
                            <v-row align="center">
                                <v-col class="grow ">
                                    <span style="color: white;"> Crear Acceso</span>
                                </v-col>

                            </v-row>
                        </v-toolbar>

                        <v-row class="mt-4 ml-4">
                            <v-col cols="12" md="4"> <v-text-field prepend-icon="mdi-door-open" v-model="name"
                                    :rules="nameRules" label="Acceso *" type="text"></v-text-field></v-col>
                        </v-row>
                        <v-divider class="mb-3"></v-divider>
                        <v-row>
                            <v-col cols="12" md="9"></v-col>
                            <v-col cols="12" md="3">
                                <v-btn to="/access" text>
                                    Cancelar
                                </v-btn>

                                <v-btn color="primary" :disabled="!valid" @click="create()">
                                    Aceptar
                                </v-btn></v-col>
                        </v-row>
                    </v-form>
                </v-card>

            </v-col>
        </v-row>

    </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
    mixins: [SnotifyMixin],
    VueSnotify,

    data() {
        return {
            name: "",
            data: {},
            valid: true,

            nameRules: [
                v => !!v || 'El Acceso es requerido',
                v => (v && v.length <= 19) || 'El Acceso debe tener menos de 20 caracteres',
            ],

        }
    },



    methods: {

        create() {
            this.data.name = this.name;
            this.valid = false,

                axios
                    .post("/entrance-store", this.data)
                    .then(() => {
                        this.displayNotification(
                            "success",
                            "Éxito",
                            "Acceso registrado"
                        );
                        setTimeout(() => this.$router.push({ name: "access" }), 3000);
                    })
                    .catch((err) => {
                        console.log(err, "error");
                        this.displayNotification(
                            "error",
                            "Error",
                            "Error al crear el acceso"
                        );
                    });
        },



    },
};
</script>