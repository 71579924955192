<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-form pt-4 v-model="valid">
            <v-toolbar color="primary">
              <v-row align="center">
                <v-col class="grow">
                  <span style="color: white"> Modificar Comuna</span>
                </v-col>
              </v-row>
            </v-toolbar>

            <v-row class="mt-4 ml-4">
              <v-col cols="12" md="4">
                <v-select
                  v-model="region"
                  no-data-text="No hay datos disponibles"
                  :rules="regionRules"
                  :items="regions"
                  item-text="name"
                  item-value="id"
                  prepend-icon="mdi-map"
                  :disabled="itemDisabled"
                  label="Nombre de la Región *"
                  persistent-hint
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  v-model="name"
                  :rules="nameRules"
                  label="Nombre de la comuna *"
                  type="text"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  prepend-icon="mdi mdi-numeric"
                  v-model="code"
                  :rules="codeRules"
                  label="Código de la comuna *"
                  type="number"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="9"></v-col>
              <v-col cols="12" md="3">
                <v-btn to="/commune" text> Cancelar </v-btn>

                <v-btn color="primary" :disabled="!valid" @click="update()">
                  Modificar
                </v-btn></v-col
              >
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data() {
    return {
      name: "",
      data: {},
      valid: true,
      region: "",
      regions: [],
      code: "",

      nameRules: [
        (v) => !!v || "El Nombre del Estado es requerido",
        (v) =>
          (v && v.length <= 40) ||
          "El Nombre del Estado debe tener menos de 41 caracteres",
      ],
      regionRules: [(v) => !!v || "La Región es requerida"],
      codeRules: [
        (v) => !!v || "El código de la comuna es requerido",
        (v) =>
          /^\d{3,5}$/.test(v) ||
          "El código de la comuna debe ser un número entero de 3 a 5 cifras",
      ],
    };
  },

  mounted() {
    this.getRegions();
    this.chargeData();
  },

  methods: {
    getRegions() {
      axios
        .get("/region-index")
        .then((response) => {
          console.log(response.data);
          this.regions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las regiones"
          );
        });
    },
    chargeData() {
      console.log("cargando datos");
      let request = {
        id: this.$route.params.id,
      };
      axios
        .post("/commune-show", request)
        .then((response) => {
          console.log(response.data);
          this.name = response.data.name;
          this.region = parseInt(response.data.region_id);
          this.code = response.data.code;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al cargar la comuna"
          );
        });
    },

    update() {
      console.log("cargando datos");
      this.data.name = this.name;
      this.data.id = this.$route.params.id;
      this.data.region_id = this.region;
      this.data.code = this.code;
      (this.valid = false),
        axios
          .post("/commune-update", this.data)
          .then(() => {
            this.displayNotification("success", "Éxito", "Comuna modificada");
            setTimeout(() => this.$router.push({ name: "commune" }), 3000);
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification(
              "error",
              "Error",
              "Error al modificar la comuna"
            );
          });
    },
  },
};
</script>
