
<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-form pt-4 v-model="valid">
            <v-toolbar color="primary">
              <v-row align="center">
                <v-col class="grow">
                  <span style="color: white"> Crear Credencial</span>
                </v-col>
              </v-row>
            </v-toolbar>
            <v-card-text>
              <v-row class="ml-4 mt-2">
                <v-col cols="12" md="5">
                  <v-select v-model="typeUser" :rules="typePersonRules" return-object :items="items" item-text="name" item-value="value"
                    label="Seleccione el tipo de persona *">
                  </v-select>
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field prepend-icon="mdi-card-account-details" v-model="rut" :rules="rutRules"
                    label="Ingrese el RUT *" type="text"></v-text-field>
                </v-col>
                <v-col cols="12" md="1">
                  <v-btn color="primary" :disabled="!valid" @click="searchUser()">
                    Buscar
                  </v-btn>
                </v-col>

              </v-row>
            </v-card-text>
            </v-form>
              <v-form pt-4 v-model="valid2">
                <v-card-text>
              <v-row>
                <v-col cols="12" class="ml-4" md="11">
                  <template>
                    <div>
                      <v-alert v-model="alert" color="red" border="left" elevation="2" colored-border
                        icon="mdi mdi-information-slab-circle" v-if="email_validation">
                        {{ message }}
                      </v-alert>
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" md="1"></v-col>
              </v-row>

              <v-row class="ml-4 mb-2" v-if="show">
                <v-col cols="12" md="11">
                  <v-card color="primary" dark>
                    <div class="d-flex flex-no-wrap justify-space-between">
                      <div>
                        <v-card-title class="text-h5">{{ name }}</v-card-title>
                        <v-card-subtitle>
                          <h5>RUT: {{ rut }}</h5>
                          <h5>Email de envío: {{ email }}</h5>
                          <h5>Teléfono: {{ phone }}</h5>
                        </v-card-subtitle>
                      </div>

                      <v-avatar class="ma-3" size="150" tile>
                  <v-img :src="getImagePath()"></v-img>
                </v-avatar>

                    </div>
                  </v-card>
                </v-col>

                <v-col cols="12" md="4">
                  <template>
                   
                      <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                        max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field class="mt-0 pt-0" v-model="computedDateFormatted"   :rules="dateRules" label="Fecha de Emisión *"
                            persistent-hint prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker  locale="es-cl" v-model="start_date"  no-title @input="menu2 = false"></v-date-picker>
                      </v-menu>
                  
                  </template>
                </v-col>
                <v-col cols="12" md="4">
                  <template>
                   
                      <v-menu v-model="menu3" :close-on-content-click="false" transition="scale-transition" offset-y
                        max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field class="mt-0 pt-0" v-model="computedDateEndFormatted"  :rules="dateRules"  label="Fecha de Expiración *"
                            persistent-hint prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker  locale="es-cl" v-model="end_date" no-title
                          @input="menu3 = false"></v-date-picker>
                      </v-menu>
              
                  </template>
                </v-col>
              </v-row>
          

            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="8"></v-col>
              <v-col cols="12" md="4">
                <v-btn class="mr-3" to="/credential" text> Cancelar </v-btn>
                <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="600">
                  <template v-slot:[`activator`]="{ on, attrs }">
                    <v-btn class="ml-6" icon v-bind="attrs" v-on="on">
                      <v-btn color="primary" :disabled="!start_date || !end_date" > Aceptar</v-btn>
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                      <v-progress-linear color="primary" indeterminate rounded v-if="loader"></v-progress-linear>
                      <v-card-text>
                        <div class="text-h7 pa-6" v-if="email != ''">
                          <strong >   {{ info }}</strong>
                          <strong v-if="flag" > {{ email }} </strong>
                        </div>
                        <div class="text-h7 pa-6" v-if="email == ''">
                          No se puede crear la credencial
                        </div>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                        <v-btn color="primary" text :disabled="itemDisabled" v-if="email != ''" @click="
                          dialog.value = true;
                        create();
                        ">Aceptar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
            </v-row>

          </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import UtilitiesMixin from '@/mixins/UtilitiesMixin.vue'

export default {
  mixins: [SnotifyMixin, UtilitiesMixin],
  VueSnotify,

  data() {
    return {
      dialog:false,
      info:"Se enviará la credencial al correo",
      flag:true,
      show: false,
      menu: false,
      start_date: null,
      menu2: false,
      menu3: false,
      end_date: null,
      itemDisabled: false,
      loader: false,
      name: "",
      rut: "",
      email: "",
      email_validation: false,
      phone: "",
      typeUser: {},
      data: {},
      valid: true,
      valid2: true,
      buttonCreate: true,
      message: "",
      items: [
        {
          name: "Alumno(a)",
          value: 1,
        },
        {
          name: "Funcionario(a)",
          value: 2,
        },
        {
          name: "Visitante",
          value: 3,
        },
      ],

      rutRules: [
        (v) => !!v || "El RUT es requerido",
        (v) => (v && v.length <= 11) || "El RUT debe tener menos de 12 caracteres",
        (v) => this.validaRut(v) || 'El RUT no es válido'],

      typePersonRules: [
        (v) => !!v || "Debe seleccionar un tipo de persona",       
      ],   

      dateRules: [(v) => !!v || "La Fecha es requerida"],
      
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },

    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.start_date);
    },
    computedDateEndFormatted() {
      return this.formatDate(this.end_date);
    },
  },

  methods: {

    getImagePath() {
   
let url = axios.defaults.baseURL.split("api/")[0] + `rut/${this.rut}.jpg`
return url
},

    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    save(date) {
      this.$refs.menu.save(date);
    },
    searchUser() {
    
      this.message = "";
      this.email_validation = false;
      this.email = "";
      let request = {
        rut: this.rut,
      };
      let route = "";
      if (this.typeUser.value == 1) {
        route = "student-show-by-rut";
      } else if (this.typeUser.value == 2) {
        route = "official-show-by-rut";
      } else if (this.typeUser.value == 3) {
        route = "visit-show-by-rut";
      }
      axios
        .post("/" + route, request)
        .then((response) => {
          console.log(response.data);

          switch (this.typeUser.value) {
            case 1:
              this.result = response.data.guardians;
              console.log("guardian")
              console.log(response.data.guardians)
              if (this.result != null) {
                this.result.forEach((element) => {
                  if (element.pivot.is_main_guardian=="1") {
                    this.email = element.email;
                  }
                });
              }
              if (this.email == "") {
                this.message =
                  "El alumno no tiene asignado un apoderado titular. No se puede crear la credencial.";
                this.email_validation = true;
                this.show = false;
              } else {
                this.name =
                  response.data.name +
                  " " +
                  response.data.last_name +
                  " " +
                  response.data.mother_last_name;
                this.phone = response.data.mobile;
                this.show = true;
              }
              break;
            case 2:
              if (response.data.email != null && response.data.email != "") {
                this.email = response.data.email;

                this.name =
                  response.data.name +
                  " " +
                  response.data.last_name +
                  " " +
                  response.data.mother_last_name;

                this.phone = response.data.mobile;
                this.show = true;
              } else {
                this.message =
                  "El funcionario no tiene asignado un correo. No se puede crear credencial. ";
                this.email_validation = true;
                this.show = false;
              }
              break;
            case 3:
              if (response.data.email != null && response.data.email != "") {
                this.email = response.data.email;

                this.name =
                  response.data.name +
                  " " +
                  response.data.last_name +
                  " " +
                  response.data.mother_last_name;

                this.phone = response.data.mobile;
                this.show = true;
              } else {
                this.message =
                  "La visita no tiene asignado un correo. No se puede crear la credencial. ";
                this.email_validation = true;
                this.show = false;
              }
              break;
          }

          if (response.data.name == undefined) {
            this.displayNotification(
              "error",
              "Error",
              "No existe un " + this.typeUser.name + " registrado con este RUT"
            );
          }
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error en la búsqueda");
        });
    },

    create() {
      this.info="Se está Generando la Credencial. Por favor espere..."
      
      this.flag=false
      this.itemDisabled = true
      this.loader = true
      this.data.rut = this.rut;
      console.log("entra a crear credencial");
      if (
        this.start_date != null &&
        this.end_date != null &&
        this.start_date > this.end_date
      ) {

        return;
      }
      this.data.start_date = this.start_date;
      this.data.end_date = this.end_date;
      this.data.status_id = 1;
      this.data.credencial_type_id = this.typeUser.value;
      this.valid = false;
      this.valid2= false;
      console.log("entra a crear credencial");
     axios
        .post("/credential-store", this.data)
        .then(() => {


          this.displayNotification("success", "Éxito", "Credencial registrada");
          this.dialog=false,
          setTimeout(() => this.$router.push({ name: "credential" }), 3000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.dialog=false
          if (this.typeUser.value == 1) {
            this.displayNotification(
              "error",
              "Error",
              "La credencial ya existe o los datos de los apoderados no han sido completados"
            );
            setTimeout(() => this.$router.push({ name: "credential" }), 3000);

          } else {
            this.displayNotification(
              "error",
              "Error",
              "La credencial se ha creado anteriormente"
            );
            setTimeout(() => this.$router.push({ name: "credential" }), 3000);
          }
        })
        .finally(() => {
          this.loader = true
        })
        ;
    },
  },
};
</script>
