<template>
    <div>
        <v-row>
          <vue-snotify></vue-snotify>
            <v-col cols="12" md="12">
                <v-card class="mt-2 mb-4 ">

                    <v-toolbar color="primary">
                        <v-row align="center">
                            <v-col class="grow ">
                                <span style="color: white;">Horarios </span>
                            </v-col>
                         <!--
<v-btn ="false" to="/create-schedule-institution" color=" mr-2">
                                <v-icon left>
                                    mdi-calendar
                                </v-icon>
                                Horario de Institución
                            </v-btn>
                         -->
                            
                            <v-btn to="/create-schedule" color=" mr-2">
                                <v-icon left>
                                    mdi-calendar
                                </v-icon>
                                Registrar Horario de Curso
                            </v-btn>

                        </v-row>
                    </v-toolbar>

                    <v-row>
            <v-col cols="6"></v-col>
            <v-col cols="5" class="ml-10">
              <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
            </v-col>
          </v-row>

          
                    <v-card-text>          


                        <v-data-table :headers="headers" :items="calendars" :search="search" 
                        :loading="loading"
              loading-text="Cargando... Por favor espere"
                        :footer-props="{
                            itemsPerPageText: 'Filas por página',
                            itemsPerPageAllText: 'todos',
                        }" no-data-text="No hay datos disponibles" no-results-text="No hay datos disponibles">
                       
                       <template v-slot:[`item.region_name`]="{ item }">                            
                            <span>{{ getRegionById(item.region_id)}}</span>
                           </template>

                           <template v-slot:[`item.grade_id`]="{ item }">
                <span>{{ getGradeById(item.grade_id) }}</span>
              </template>


           <template v-slot:[`item.actions`]="{ item }">   
             
          

            <v-tooltip top>
              <template v-slot:[`activator`]="{ on, attrs }"> 
                <v-btn icon :to="'/show-calendar/' + item.id">
                  <v-icon v-bind="attrs" color="cyan" v-on="on">
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>

              <span>Ver</span>
            </v-tooltip>
             
            

            <v-tooltip top>
              <template v-slot:[`activator`]="{ on, attrs }"> 
                <v-btn icon :to="'/update-schedule/' + item.id">
                  <v-icon v-bind="attrs" color="primary" v-on="on">
                    mdi-pencil-box-multiple
                  </v-icon>
                </v-btn>
              </template>

              <span>Modificar</span>
            </v-tooltip>

            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:[`activator`]="{ on, attrs }"> 
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon v-bind="attrs" color="red darken-2" v-on="on">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">      
                <v-card>
                  <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                  <v-card-text>
                    <div class="text-h7 pa-6">
                      ¿Estás seguro que deseas eliminar el calendario?
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                    <v-btn
                      class="primary"
                      text
                      
                      @click="
                       dialog.value = false;
                        deleteItem(item);
                      "
                      >Aceptar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </template>
            

                        </v-data-table>
                    
                    </v-card-text>

                </v-card>
            </v-col>

            
        </v-row>

    </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
    mixins: [SnotifyMixin],
    VueSnotify,

    data() {
        return {
          loading:true,
          grades: [],
            drawer: true,
            dialog: false,
            search: "",
            region:"",
            cont:0,
            headers: [              
                    
                { text: "Nombre", value: "name" },
                { text: "Curso", value: "grade_id" },
                { text: "Acciones", value: "actions" },               
            ],

            calendars: [], 
            regions:[],         

        }
    },

    mounted() {
    this.action = "Eliminar" 
    this.getCalendars()
    this.getGrades() 
   
  },

    methods: {  

      getGrades() {
      axios
        .get("/grade-index")
        .then((response) => {
          this.grades = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },

      getGradeById(grade_id) {
      let grade = this.grades.find((c) => c.id == grade_id);
      return grade.name;
    },

    getRegionById(region_id) {
      let region = this.regions.find((c) => c.id == region_id);
      return region.name;
    },
      deleteItem(item) {       
    
      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/calendar-destroy", request)
        .then(() => {
          this.getCalendars()
          this.displayNotification("success", "Éxito", "Celendario eliminado");
        
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar el calendario"
          );
        });
    },
    getCalendars()
        {
            axios
        .get("/calendar-index")
        .then((response) => {  
          console.log(response.data)
          this.calendars = response.data;         
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        }).finally(()=>
        {
          this.loading=false
        });
        },     
        getRegions()
        {
            axios
        .get("/region-index")
        .then((response) => {  
          console.log(response.data)
          this.regions = response.data;         
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
        },   
    },
};
</script>