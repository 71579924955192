import { defineStore } from 'pinia'

export const useDoorStore = defineStore('door  ', {
  state: () => ({ 
    id: 0 ,
    name:"Acceso"  
  }),
 
  actions: {
    setDoor(id, name) {
      this.id=id;
      this.name =name;
    },
  },
})
