<template>
  <div>
    <v-row>
      <vue-snotify></vue-snotify>
      <v-col cols="12" md="12">
        <v-card class="mt-2 mb-4">
          <v-form pt-4 v-model="valid">
            <v-toolbar color="primary">
              <v-row align="center">
                <v-col class="grow">
                  <span style="color: white"> Registrar Institución</span>
                </v-col>
              </v-row>
            </v-toolbar>

            <v-card-text>
              <v-row class="mt-4 ml-4 mr-4">
                <v-col cols="12" md="4">
                  <v-file-input v-validate="'required'" ref="fileInput" v-model="file" name="file" accept=".png, .jpg"
                    label="Seleccione el Logotipo (*.jpg, *.png)" @change="onFileSelected"></v-file-input>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field prepend-icon="mdi-card-account-details" :counter="11" v-model="rut" :rules="rutRules"
                    placeholder="12345678-9" label="RUT *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4">
                  <v-text-field prepend-icon="mdi-school" v-model="name" :rules="nameRules"
                    label="Nombre de la Institución *" type="text"></v-text-field></v-col>

                <v-col cols="12" md="4">
                  <v-text-field prepend-icon="mdi-alpha-p-box" v-model="managerName" :rules="manager_name_Rules"
                    label="Nombre del Representante *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4">
                  <v-text-field prepend-icon="mdi-cellphone" v-model="mobile" :rules="mobileRules" label="Teléfono *"
                    v-mask="'+56#########'" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4">
                  <v-text-field prepend-icon="mdi-map-marker" v-model="address" :rules="addressRules" label="Dirección *"
                    type="text"></v-text-field></v-col>
                <v-col cols="12" md="4">
                  <v-select v-model="region" no-data-text="No hay datos disponibles" :rules="regionRules" :items="regions"
                    item-text="name" item-value="id" prepend-icon="mdi-map" :disabled="itemDisabled" label="Región *"
                    @change="getCommunes()" persistent-hint>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select v-model="commune" no-data-text="No hay datos disponibles" :rules="communeRules"
                    :items="communes" item-text="name" item-value="id" prepend-icon="mdi-account-details"
                    :disabled="itemDisabled" label="Comuna *" persistent-hint>
                  </v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field prepend-icon="mdi-email-fast" v-model="email" :rules="emailRules"
                    label="Correo Electrónico *" type="text"></v-text-field></v-col>
                <v-col cols="12" md="4">
                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="dates" label="Rango de Fecha de Vigencia de Luupa"
                        prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="dates" range locale="es-cl">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field prepend-icon="mdi-clock" v-model="late_arrivals" :rules="late_arrivalsRules"
                    label="Retrasos permitidos *" type="text"></v-text-field></v-col>

                <v-col cols="12" md="3"> </v-col>
                <v-col cols="12" md="3">
                  <v-checkbox v-model="whatsapp_notification" label="Notificación por Whatsapp"></v-checkbox>
                </v-col>
                <v-col cols="12" md="3">
                  <v-checkbox v-model="email_notification" label="Notificación por Correo"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="9"></v-col>
              <v-col cols="12" md="3">
                <v-btn to="/institution" class="mr-2" text> Cancelar </v-btn>
                <v-btn color="primary" :disabled="!valid" @click="create()">
                  Aceptar
                </v-btn></v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import UtilitiesMixin from '@/mixins/UtilitiesMixin.vue'
export default {
  mixins: [SnotifyMixin, UtilitiesMixin],
  VueSnotify,

  data() {
    return {
      data: {},
      valid: true,
      file: null,
      //Datos de la Institucion
      rut: "",
      name: "",
      managerName: "",
      email: "",
      late_arrivals: "",
      mobile: "",
      address: "",
      dates: ["", ""],
      region: "",
      commune: "",
      whatsapp_notification: false,
      email_notification: false,
      regions: [],
      communes: [],
      rutRules: [
        (v) => !!v || "El RUT es requerido",
        (v) => (v && v.length <= 11) || "El RUT debe tener menos de 12 caracteres",
        (v) => this.validaRut(v) || 'El RUT no es válido'],
      nameRules: [
        (v) => !!v || "El Nombre es requerido",
        (v) =>
          (v && v.length <= 40) ||
          "El Nombre debe tener menos de 41 caracteres",
      ],
      manager_name_Rules: [
        (v) => !!v || "El nombre del Gerente es requerido",
        (v) =>
          (v && v.length <= 40) ||
          "El nombre del Gerente debe tener menos de 41 caracteres",
      ],
      late_arrivalsRules: [(v) => !!v || "Retrasos permitidos es requerido"],
      regionRules: [(v) => !!v || "La Región es requerida"],
      communeRules: [(v) => !!v || "La Comuna es requerida"],
      mobileRules: [(v) => !!v || "El Teléfono es requerido"],
      addressRules: [
        (v) => !!v || "La Dirección es requerida",
        (v) =>
          (v && v.length <= 200) ||
          "La Dirección debe tener menos de 201 caracteres",
      ],

      emailRules: [
        (v) => !!v || "El Correo Electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico no es válido",
      ],
    };
  },
  mounted() {
    this.action = "Eliminar";

    this.getRegions();
    this.getCommunes();
  },

  methods: {
    onFileSelected(event) {
      this.file = event.target.files[0];
      console.log("carga imagen");
      console.log(this.file);
    },
    getRegions() {
      axios
        .get("/region-index")
        .then((response) => {
          this.regions = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las regiones"
          );
        });
    },

    getCommunes() {
      this.data.region_id = this.region;

      axios
        .post("/commune-show-by-region", this.data)
        .then((response) => {
          this.communes = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener las comunas"
          );
        });
    },
    create() {

      this.valid = false;
      const formData = new FormData();
      formData.append("image", this.file);
      formData.append("rut", this.rut);
      formData.append("name", this.name);
      formData.append("manager_name", this.managerName);
      formData.append("email", this.email);
      formData.append("phone", this.mobile);
      formData.append("direction", this.address);
      formData.append("late_arrivals", this.late_arrivals);
      formData.append("start_date", this.dates[0]);
      formData.append("end_date", this.dates[1]);
      formData.append("region_id", this.region);
      formData.append("commune_id", this.commune);
      formData.append("whatsapp_notification", this.whatsapp_notification);
      formData.append("email_notification", this.email_notification);
      axios
        .post("/institution-store", formData)
        .then(() => {
          this.displayNotification(
            "success",
            "Éxito",
            "Institución registrada"
          );
          setTimeout(() => this.$router.push({ name: "institution" }), 3000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al crear la Institución"
          );
        });
    },
  },
};
</script>
